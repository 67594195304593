import { Icon } from '@iconify/react';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'src/components/shared/Avatar';
import CalendarEvents from 'src/components/shared/CalendarEvents';
import Card from 'src/components/shared/Card';
import Dropdown from 'src/components/shared/Dropdown';
import Image from 'src/components/shared/Image';
import ItemList from 'src/components/shared/ItemList';
import SearchBox from 'src/components/shared/SearchBox';
import SharedTime from 'src/components/shared/SharedTime';
import Title from 'src/components/shared/Title';

type Tab = 'conversation' | 'activities' | 'overflow' | 'documents' | 'events' | 'lawyers';
type ITab = {
  icon: string;
  type: Tab;
  title: string;
};

export default function id() {
  const [selectedTab, setSelectedTab] = useState<Tab>('overflow');
  const tabs: ITab[] = [
    {
      icon: 'ant-design:read-outlined',
      type: 'overflow',
      title: 'Overflow'
    },
    {
      icon: 'carbon:chat',
      type: 'conversation',
      title: 'Conversation'
    },
    {
      icon: 'solar:list-check-minimalistic-bold',
      type: 'activities',
      title: 'Activities'
    },
    {
      icon: 'basil:document-outline',
      type: 'documents',
      title: 'Documents'
    },
    {
      icon: 'solar:calendar-linear',
      type: 'events',
      title: 'Events'
    },
    {
      icon: 'heroicons-outline:users',
      type: 'lawyers',
      title: 'Lawyers'
    }
  ];
  const tracking = [
    {
      label: 'Started'
    },
    {
      label: 'Approved'
    },
    {
      label: 'Archived'
    }
  ];

  const MEMO_TABS = useMemo(() => {
    switch (selectedTab) {
      case 'conversation':
        return <Conversation />;
      case 'overflow':
        return <Overflow />;
      case 'activities':
        return <Activities />;
      case 'documents':
        return <Documents />;
      case 'events':
        return <CalendarEvents />;
      case 'lawyers':
        return <Lawyers />;
      default:
        return <Conversation />;
    }
  }, [selectedTab]);
  return (
    <div className="p-6">
      <div className="flex items-start gap-4">
        <div className="flex-1 shrink-0">
          <Card className="flex flex-col">
            <div className="-mx-4 px-4 pb-4 border-b border-b-gray-200">
              <div className="flex items-center gap-3 justify-between">
                <p className="text-base font-semibold text-gray-600">#{Date.now().toString()}</p>
                <Dropdown
                  button={
                    <>
                      <Icon
                        icon="pepicons-pop:dots-y"
                        width="18"
                      />
                    </>
                  }
                  position="top-right"
                  className="!rounded-full !p-2"
                >
                  <ul className="p-3 divide-y divide-gray-200">
                    <ItemList className="py-2">
                      <button
                        type="button"
                        className="btn-with-icon !p-0 !text-gray-600"
                      >
                        <Icon
                          icon="lucide:user-plus"
                          width="20"
                        />
                        <span>Assign lawyer</span>
                      </button>
                    </ItemList>

                    <ItemList className="py-2">
                      <button
                        type="button"
                        className="btn-with-icon !p-0 !text-gray-600"
                      >
                        <Icon
                          icon="lucide:file-edit"
                          width="20"
                        />
                        <span>Modify case</span>
                      </button>
                    </ItemList>

                    <ItemList className="py-2">
                      <button
                        type="button"
                        className="btn-with-icon !p-0 !text-gray-600"
                      >
                        <Icon
                          icon="solar:inbox-archive-linear"
                          width="20"
                        />
                        <span>Upload to archive</span>
                      </button>
                    </ItemList>

                    <ItemList className="py-2">
                      <button
                        type="button"
                        className="btn-with-icon !p-0 !text-gray-600"
                      >
                        <Icon
                          icon="streamline:legal-justice-hammer-hammer-work-legal-mallet-office-company-gavel-justice-judge-arbitration-court"
                          width="20"
                        />
                        <span>Add action</span>
                      </button>
                    </ItemList>
                  </ul>
                </Dropdown>
              </div>
            </div>

            <ul className="flex items-center whitespace-nowrap overflow-x-auto -mx-4  border-b border-b-gray-200">
              {tabs.map((tab, index) => (
                <li key={index}>
                  <TabButton
                    item={tab}
                    activeTab={selectedTab}
                    onTab={setSelectedTab}
                  />
                </li>
              ))}
            </ul>

            <div className="flex-1 p-4 -mx-4 space-y-2 max-h-[calc(58vh)] overflow-y-auto">
              {MEMO_TABS}
            </div>
            {selectedTab === 'conversation' ? (
              <div className="-mx-4 px-4 pb-4 border-t border-t-gray-200 pt-4">
                <form className="space-y-3">
                  <div className="flex items-end gap-3">
                    <textarea
                      className="form-textarea flex-1 min-h-fit"
                      placeholder="Type content here.."
                    ></textarea>
                    <button
                      type="submit"
                      className="btn-with-icon !bg-primary !text-gray-800"
                    >
                      <Icon
                        icon="iconamoon:send-fill"
                        width="20"
                      />
                    </button>
                  </div>
                  <div className="flex items-center gap-3 flex-wrap">
                    <button
                      type="button"
                      className="btn-with-icon outline-btn"
                    >
                      <Icon
                        icon="iconamoon:attachment-duotone"
                        width="20"
                      />
                      <span>Attachments</span>
                    </button>
                  </div>
                </form>
              </div>
            ) : null}
          </Card>
        </div>
        <div className="flex-1 shrink-0 max-w-sm space-y-3">
          <Title title="Case details" />
          <Card>
            <ul className="divide-y divide-gray-200">
              <ItemList className="py-3">
                <div className="grid grid-cols-2 gap-2">
                  <Title title="Case no." />
                  <Title
                    title={Date.now().toString()}
                    className="text-end text-gray-800"
                  />
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="grid grid-cols-2 gap-2">
                  <Title title="Category" />
                  <Title
                    title="Family"
                    className="text-end text-gray-800"
                  />
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="grid grid-cols-2 gap-2">
                  <Title title="Court" />
                  <Title
                    title="Family Court, Riyadh"
                    className="text-end text-gray-800"
                  />
                </div>
              </ItemList>
              <ItemList className="py-3">
                <div className="grid grid-cols-2 gap-2">
                  <Title title="Created date" />
                  <Title
                    title={moment(new Date()).format('lll')}
                    className="text-end text-gray-800"
                  />
                </div>
              </ItemList>
            </ul>
          </Card>
          <Title title="Case tracking" />
          <Card>
            <ul className="divide-y divide-gray-200">
              {tracking.map((item, index) => (
                <ItemList
                  className="py-3"
                  key={index}
                >
                  <Tracking item={item} />
                </ItemList>
              ))}
            </ul>
          </Card>
        </div>
      </div>
    </div>
  );
}

function Conversation() {
  return (
    <>
      <CenterDate date={new Date()} />
      <ChatCard sender={false} />
      <ChatCard sender />
      <ChatCard sender />
    </>
  );
}

function Overflow() {
  return (
    <>
      <div className="space-y-3">
        <Title title="Client details" />
        <ul className="divide-y divide-gray-200">
          <ItemList className="py-3">
            <div className="grid grid-cols-2 gap-2">
              <Title title="Name" />
              <Title
                title="Mohammed"
                className="text-end text-gray-800"
              />
            </div>
          </ItemList>
          <ItemList className="py-3">
            <div className="grid grid-cols-2 gap-2">
              <Title title="Mobile" />
              <Title
                title="9665 4509 681"
                className="text-end text-gray-800"
              />
            </div>
          </ItemList>
          <ItemList className="py-3">
            <div className="grid grid-cols-2 gap-2">
              <Title title="Mobile" />
              <Title
                title="client@justice.sa"
                className="text-end text-gray-800"
              />
            </div>
          </ItemList>
          <ItemList className="py-3">
            <div className="grid grid-cols-2 gap-2">
              <Title title="Case category" />
              <Title
                title="Family"
                className="text-end text-gray-800"
              />
            </div>
          </ItemList>
        </ul>

        <div className="space-y-2">
          <p className="text-lg font-semibold">Case title</p>
          <p className="text-sm text-gray-500">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Labore nesciunt officiis
            aspernatur minus ex incidunt nam saepe. Quaerat pariatur autem sequi dolore, voluptatem
            molestias saepe est cupiditate architecto quo animi?
          </p>
        </div>

        <Title title="Extra information" />
        <p className="text-sm text-black">
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolorum fugiat eum voluptates
          saepe error velit odio architecto eveniet praesentium dolores non cupiditate sit ducimus
          ab nihil natus vitae reiciendis, autem maxime! Doloremque ducimus ullam beatae quia totam
          quidem nostrum rerum! Culpa eum veniam sint voluptate magni debitis consequatur
          praesentium earum. Explicabo quo repellat laborum saepe aperiam maiores enim dignissimos
          vel. Voluptatem, mollitia perspiciatis ratione quisquam alias amet, tempore eaque sed iure
          quasi aliquid pariatur hic earum magnam labore veniam error tempora adipisci optio
          doloremque facilis aliquam exercitationem! Accusamus vero corporis nostrum deserunt unde
          nemo illo, commodi reprehenderit magni odio iure magnam labore excepturi praesentium
          dolorem voluptates atque repellendus vitae ipsa recusandae reiciendis, esse veniam id?
          Sunt possimus necessitatibus accusantium pariatur. Asperiores aut sapiente nisi, odit in
          deserunt eaque quidem blanditiis consequatur repudiandae sit eligendi sint temporibus
          assumenda esse error corporis id, nam vero nulla itaque. Incidunt provident, quaerat
          adipisci quod doloremque quos porro, corrupti nisi aliquam earum eveniet quo reprehenderit
          optio soluta nobis laborum debitis iure ut sit minima quasi exercitationem! In, corporis.
          Veniam tenetur animi eveniet quo pariatur porro esse cum doloribus molestias cupiditate a
          sequi, magni iure incidunt, et quasi, harum dolore libero quaerat eaque! Alias, tempora
          reiciendis.
        </p>
        <Title title="Attachments" />
        <ul className="flex items-center gap-3 flex-wrap">
          {Array.from({ length: 4 }).map((_e, index: number) => (
            <li key={index}>
              <Link
                target="_blank"
                to="/"
                className="btn-with-icon outline-btn !py-1.5 !px-3"
              >
                <span>attachment.pdf</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
}

function Activities() {
  return (
    <>
      <CenterDate date={new Date()} />
      <ul className="divide-y divide-gray-200">
        <ItemList className="py-3">
          <ActivityCard />
        </ItemList>
      </ul>
    </>
  );
}

function ActivityCard() {
  const time = moment().format('hh:mm:A');

  return (
    <>
      <div className="flex items-start gap-4">
        <Image className="w-6 h-6 object-cover rounded-full shrink-0" />
        <div className="flex-1 space-y-1">
          <p className="text-sm text-gray-500">
            The status of the case has been changed by "
            <span className="font-semibold text-black">Mohamed</span>" from{' '}
            <span className="font-semibold text-black">New</span> to{' '}
            <span className="font-semibold text-black">Under review</span>
          </p>
          <p className="text-xs text-gray-500">{time}</p>
        </div>
      </div>
    </>
  );
}

function Documents() {
  return (
    <>
      <div className="sticky top-0 z-10 pb-2">
        <SearchBox className="w-full" />
      </div>
      <ul className="divide-y divide-gray-200 -mx-4 p-4 border-t border-t-gray-200">
        {Array.from({ length: 10 }).map((_e, index: number) => (
          <ItemList
            className="py-3"
            key={index}
          >
            <Link
              to="#"
              className="flex items-center gap-3"
            >
              <Avatar icon="ph:file-pdf" />
              <div className="space-y-0.5 flex-1">
                <p className="text-sm font-semibold line-clamp-1">attachment.pdf</p>
                <SharedTime date={new Date()} />
              </div>
            </Link>
          </ItemList>
        ))}
      </ul>
    </>
  );
}

function Lawyers() {
  return (
    <div className="grid grid-wrapper gap-3">
      {Array.from({ length: 4 }).map((_e, index) => (
        <Card key={index}>
          <div className="flex flex-col gap-3 items-center justify-center">
            <Image className="w-14 h-14 rounded-full object-cover" />
            <div className="text-center space-y-1">
              <p className="text-sm font-semibold">Lawyer name</p>
              <p className="text-xs text-gray-500">+9665 4509 6681</p>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
}

function Tracking({ item }: any) {
  return (
    <div className="flex items-center gap-3 relative">
      <span
        className={[
          'w-6 h-6 rounded-full flex items-center justify-center',
          item.created_at ? 'bg-teal-600 text-white' : 'bg-gray-200 text-gray-500'
        ].join(' ')}
      >
        <Icon
          icon={item.created_at ? 'uil:check' : 'bx:stop'}
          width="16"
        />
      </span>
      <div className="flex-1 space-y-0.5">
        <Title title={item.label} />
        <div>
          <ul className="inline-flex flex-wrap gap-2">
            <li>
              <div className="flex items-center gap-1">
                <Icon
                  icon="solar:user-bold-duotone"
                  width="16"
                />
                <Title
                  title={item.user?.name || 'N/A'}
                  className="!text-xs"
                />
              </div>
            </li>
            <li>
              <div className="flex items-center gap-1">
                <Icon
                  icon="uil:clock-three"
                  width="16"
                />
                <Title
                  title={moment(item?.created_at).format('lll') || 'N/A'}
                  className="!text-xs"
                />
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export function TabButton({
  item,
  activeTab,
  onTab
}: {
  item: ITab;
  activeTab: Tab;
  onTab: (value: Tab) => any;
}) {
  return (
    <button
      type="button"
      className={[
        'btn-with-icon !rounded-none !px-4 !py-3 transition-all',
        activeTab == item.type
          ? '!text-black border-b-2 border-b-primary font-semibold'
          : '!text-gray-500'
      ].join(' ')}
      onClick={() => onTab(item.type)}
    >
      <Icon
        icon={item.icon}
        width="20"
      />
      <span>{item.title}</span>
    </button>
  );
}

export function CenterDate({ date }: { date: Date }) {
  const momentDate = moment(date).format('DD-MM-YYYY');
  return (
    <div className="flex items-center justify-center gap-3 w-full !my-4 first:!mt-0 last:mb-auto">
      <span className="font-medium py-2 px-4 rounded-full bg-gray-200 text-gray-600 text-xs text-center">
        {momentDate}
      </span>
    </div>
  );
}

export function ChatCard({ sender = true }: { sender: boolean }) {
  const time = moment().format('hh:mm:A');
  const type = sender ? 'sender' : 'receiver';
  const classes = {
    sender: {
      card: 'p-3 rounded-md bg-primary text-black',
      cardWrapper: 'w-fit max-w-[80%] space-y-1 me-auto',
      attachments: 'flex items-center gap-2 flex-wrap justify-start',
      date: 'text-xs text-gray-500 text-end'
    },
    receiver: {
      card: 'p-3 rounded-md bg-gray-100 text-black',
      cardWrapper: 'w-fit max-w-[80%] space-y-1 ms-auto',
      attachments: 'flex items-center gap-2 flex-wrap justify-end',
      date: 'text-xs text-gray-500 text-start'
    }
  };
  return (
    <>
      <div className="space-y-2">
        <div className={classes[type].cardWrapper}>
          <p className={classes[type].date}>{time}</p>
          <div className="flex items-center gap-3">
            {type === 'sender' ? <Image className="w-6 h-6 object-cover rounded-full" /> : null}
            <div className="flex-1 space-y-2">
              <div className={classes[type].card}>
                <p className="text-sm">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit. Commodi officia maxime
                  illo, quod ad iure repellendus, fugit ducimus laborum eveniet repellat, quia
                  quisquam nesciunt error doloribus ipsa dolor ut rem!
                </p>
              </div>

              <ul className={classes[type].attachments}>
                {Array.from({ length: 4 }).map((_e, index: number) => (
                  <li key={index}>
                    <Link
                      target="_blank"
                      to="/"
                      className="btn-with-icon outline-btn !py-1.5 !px-3"
                    >
                      <span>attachment.pdf</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
