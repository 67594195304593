import { Icon } from '@iconify/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function AppBar({
  center = false,
  title,
  actions
}: {
  center?: boolean;
  title: string;
  actions?: React.ReactNode;
}) {
  const navigate = useNavigate();
  return (
    <div className="w-full bg-gray-50 px-6 py-4 border-b border-b-gray-200">
      <div className={center ? 'xl:container mx-auto' : ''}>
        <div className="flex items-center gap-4">
          <button
            className="text-gray-700"
            onClick={() => navigate(-1)}
          >
            <Icon
              icon="humbleicons:arrow-left"
              width="20"
              className="block rtl:hidden"
            />
            <Icon
              icon="humbleicons:arrow-right"
              width="20"
              className="hidden rtl:block"
            />
          </button>
          <div className="flex-1">
            <p className="text-base font-semibold text-gray-600">{title}</p>
          </div>
          {actions}
        </div>
      </div>
    </div>
  );
}
