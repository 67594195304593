import { Icon } from '@iconify/react';
import { omit } from 'lodash';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddForm from 'src/components/cases/AddForm';
import ExportOptions from 'src/components/shared/ExportOptions';
import LimitedViewItem from 'src/components/shared/LimitedViewItem';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import prepareRequest from 'src/helper/prepareRequest';

export default function index() {
  let render = true;
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addVisible, setAddVisible] = useState<boolean>(false);
  const [responses, setResponses] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({});
  const [filter, setFilter] = useState<any>({});

  useEffect(() => {
    if (!render) return;
    GetItems();
    render = false;
  }, []);

  function GetItems(params?: any) {
    setIsLoading(true);
    const queries = { ...params, ...filter };
    const paginate = omit(queries, ['total_pages', 'current_page', 'count']);
    prepareRequest(
      {
        url: 'cases',
        params: {
          page: 1,
          ...paginate
        }
      },
      (data) => {
        const cases = data.result.cases;
        setResponses(() => cases.data);
        setPagination(() => ({ ...paginate, ...cases.pagination }));
      }
    ).finally(() => setIsLoading(false));
  }

  return (
    <div className="p-6">
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>Case</th>
              <th>Clients</th>
              <th>Litigants</th>
              <th>Workteam</th>
              <th>Judge</th>
              <th>Status</th>
              <th>Created date</th>
              <th></th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses.map((item) => (
                <tr key={item.id}>
                  <td>
                    <Link to={'/cases/' + [item.id, item.tracking_id].join('-')}>
                      <p>{item.name || '-'}</p>
                      <p className="text-blue-600">{item.id || '-'}</p>
                    </Link>
                  </td>

                  <td>
                    <LimitedViewItem
                      data={item.clients}
                      count={2}
                    />
                  </td>
                  <td>
                    <LimitedViewItem
                      data={item.litigants}
                      count={2}
                    />
                  </td>
                  <td>
                    <LimitedViewItem
                      data={item.lawyers}
                      count={2}
                    />
                  </td>

                  <td>{item.judge_name || '-'}</td>
                  <td>{item.status || '-'}</td>

                  <td>
                    <SharedTime date={item.created_at} />
                  </td>
                  <td></td>
                </tr>
              ))}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <button
                type="button"
                className="btn-with-icon !text-gray-600"
                onClick={() => setAddVisible(true)}
              >
                <Icon
                  icon="ic:baseline-plus"
                  width="18"
                />
                <span>Add new</span>
              </button>{' '}
              <ExportOptions
                excelPathname="cases/export_excel"
                cvsPathname="cases/export_csv"
                pathname="cases/send_excel"
                pdfPathname="cases/export_pdf"
              />
            </>
          );
        }}
        isEmpty={!responses.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) =>
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            })),

          onKeyDown: (e) => {
            if (e.key === 'Enter') GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isLoading || isUpdating}
      />

      <Modal
        visible={addVisible}
        handleClose={() => setAddVisible(false)}
        title="Add new"
        size="!max-w-screen-xl"
      >
        <AddForm
          closeModal={setAddVisible}
          reFetching={GetItems}
        />
      </Modal>
    </div>
  );
}
