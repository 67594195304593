export interface TitleInterface {
  [key: string]: string;
}

export const titles: TitleInterface = {
  '/': 'dashboard',
  '/account/login': 'Login',
  '/account/profile': 'profile',
  '/calendar': 'calendar',
  '/appointments': 'appointments',
  '/cases': 'cases',
  '/consulting': 'consulting',
  '/invoices': 'invoices',
  '/archives': 'archives',
  '/reports': 'reports',
  '/notifications': 'notifications',
  '/settings': 'settings',
  '/settings/employees': 'employees',
  '/settings/clients': 'clients',
  '/settings/courts': 'courts',
  '/settings/salaries': 'salaries',
  '/settings/categories': 'categories',
  '/settings/services': 'services',
  '/settings/statuses': 'statuses',
  '/settings/roles': 'Roles & Permissions',
  '/messages/*': 'messages',
  '*': 'page-not-found'
};
