import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Location, useLocation } from 'react-router-dom';
import { RootState } from 'src/store';
import { titles } from '../helper/PageTitles';

export default function ScrollToTop(): any {
  const { pathname }: Location = useLocation();
  const { token } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(pathname, titles);
    document.title = titles[pathname] ?? 'Justice';
  }, [pathname]);

  return null;
}
