import { Icon } from '@iconify/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

interface FormInterface {
  name: string | undefined;
  item_id: string | undefined;
  item_type: string | undefined;
  details: string | undefined;
}

type ItemType = 'case';

export default function AddDocumentForm({
  closeModal,
  reFetching,
  itemId,
  itemType
}: {
  closeModal: any;
  reFetching: any;
  itemId: string | undefined;
  itemType: ItemType;
}) {
  const { t } = useTranslation();
  const globalValues = {
    name: undefined,
    item_id: itemId,
    item_type: itemType,
    details: undefined
  } satisfies FormInterface;
  const [initialValues, setInitialValues] = useState(globalValues);
  const [errors, setErrors] = useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);

  function submitHandler(values: any, helper: any) {
    setDisabled(true);
    prepareRequest(
      {
        url: 'documents/add',
        method: 'post',
        data: values
      },
      async (data, error) => {
        if (error) return setErrors(error);
        helper.resetForm();
        await reFetching();
        closeModal(false);
      }
    ).finally(() => setDisabled(false));
  }

  const { formik } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      <div className="form-group">
        <label className="form-label">{t('name')}</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.name}
          name="name"
          onChange={formik.handleChange}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('details')}</label>
        <textarea
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.details}
          name="details"
          onChange={formik.handleChange}
        ></textarea>
        {errors?.details ? <span className="form-error">{errors?.details}</span> : null}
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('submit')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
}
