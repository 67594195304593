import { omit } from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ExportOptions from 'src/components/shared/ExportOptions';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import prepareRequest from 'src/helper/prepareRequest';

export default function index() {
  let render = true;
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responses, setResponses] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({});
  const [filter, setFilter] = useState<any>({});

  useEffect(() => {
    if (!render) return;
    GetItems();
    render = false;
  }, []);

  function GetItems(params?: any) {
    setIsLoading(true);
    const queries = { ...params, ...filter };
    const paginate = omit(queries, ['total_pages', 'current_page', 'count']);
    prepareRequest(
      {
        url: 'invoices',
        params: {
          page: 1,
          ...paginate
        }
      },
      (data) => {
        const invoices = data.result.invoices;
        setResponses(() => invoices.data);
        setPagination(() => ({ ...paginate, ...invoices.pagination }));
      }
    ).finally(() => setIsLoading(false));
  }

  return (
    <div className="p-6">
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>#</th>
              <th>Client</th>
              <th>Case</th>
              <th>Event</th>
              <th>Status</th>
              <th>Amount</th>
              <th>Paid date</th>
              <th>Created date</th>
              <th></th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses.map((item) => (
                <tr key={item.id}>
                  <td>
                    {/* <Link
                      to={'/invoices/' + item.id}
                      className="text-blue-600 font-semibold"
                    >
                    {item.id}
                  </Link> */}
                    {item.id}
                  </td>
                  <td>
                    <p>{item.client?.name || '-'}</p>
                    <p>{item.client?.mobile || '-'}</p>
                  </td>
                  <td>
                    <p>{item.case?.name || '-'}</p>
                  </td>
                  <td>{item.case_event?.name || '-'}</td>
                  <td>{item.paid_at ? t('paid') : t('unpaid')}</td>
                  <td>{CurrencyFormatter(item.amount || 0)}</td>

                  <td>
                    <SharedTime date={item.paid_at} />
                  </td>
                  <td>
                    <SharedTime date={item.created_at} />
                  </td>
                  <td></td>
                </tr>
              ))}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <ExportOptions
                excelPathname="invoices/export_excel"
                cvsPathname="invoices/export_csv"
                pathname="invoices/send_excel"
                pdfPathname="invoices/export_pdf"
              />
            </>
          );
        }}
        isEmpty={!responses.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) =>
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            })),

          onKeyDown: (e) => {
            if (e.key === 'Enter') GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isLoading || isUpdating}
      />
    </div>
  );
}
