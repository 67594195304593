import { Icon } from '@iconify/react';
import { InputHTMLAttributes, KeyboardEvent, useCallback, useMemo, useState } from 'react';

type KeyType = 'length' | 'numbers' | 'letters' | 'symbols';

export default function Password({ className, ...props }: InputHTMLAttributes<HTMLInputElement>) {
  const globalSteps: any = [
    {
      type: 'invalid',
      text: 'Password must be between 8 to 255 characters',
      key: 'length'
    },
    {
      type: 'invalid',
      text: 'It must include numbers',
      key: 'numbers'
    },
    {
      type: 'invalid',
      text: 'The password must include uppercase and lowercase letters',
      key: 'letters'
    },
    {
      type: 'invalid',
      text: 'The password must include symbols',
      key: 'symbols'
    }
  ];
  const [steps, setSteps] = useState<Array<IProps & { key: KeyType }>>(globalSteps);
  const [type, setType] = useState<InputHTMLAttributes<HTMLInputElement>['type']>('password');

  const Button = useMemo(() => {
    return (
      <button
        className="w-10 h-full flex items-center justify-center absolute top-1/2 -translate-y-1/2 right-1 rtl:right-auto rtl:left-1 appearance-none text-gray-500"
        type="button"
        onClick={() => {
          if (type === 'password') {
            setType('text');
          } else {
            setType('password');
          }
        }}
      >
        <Icon
          icon={type === 'password' ? 'ri:eye-line' : 'ri:eye-off-line'}
          width={18}
        />
      </button>
    );
  }, [type]);

  const checkPasswordValidator = useCallback((ev: KeyboardEvent<HTMLInputElement>, items: any) => {
    const { value }: any = ev.target;

    const result: any = items.map((step: any) => {
      if (!value) return { ...step, type: 'invalid' };

      let object = step;
      if (step.key === 'length') {
        if (value.length >= 8 && value.length <= 255)
          object = {
            ...step,
            type: 'valid'
          };
      } else if (step.key === 'letters') {
        if (/[a-zA-Z]/g.test(value))
          object = {
            ...step,
            type: 'valid'
          };
      } else if (step.key === 'numbers') {
        if (/[0-9]*/gm.test(value))
          object = {
            ...step,
            type: 'valid'
          };
      } else if (step.key === 'symbols') {
        if (/[!@#$%^&*(),.?":{}|<>]/g.test(value))
          object = {
            ...step,
            type: 'valid'
          };
      }
      return object;
    });

    console.table(result, value);

    setSteps(() => result);
  }, []);

  return (
    <div className="space-y-4">
      <div className="relative">
        <input
          type={type}
          className={[className, 'pr-12 rtl:pr-auto rtl:pl-12'].join(' ')}
          placeholder="••••••••"
          {...props}
          onKeyDown={(ev) => checkPasswordValidator(ev, steps)}
        />
        {Button}
      </div>
      {/* <ul className="grid grid-cols-1 sm:grid-cols-2 gap-3">
        {steps.map((step, index) => (
          <StepItem
            key={index}
            text={step.text}
            type={step.type}
          />
        ))}
      </ul> */}
    </div>
  );
}

type ValidType = 'valid' | 'invalid';
type IProps = { type: ValidType; text: string };

function StepItem({ type = 'invalid', text }: IProps) {
  const icon: Record<ValidType, string> = {
    valid: 'solar:check-circle-bold',
    invalid: 'solar:close-circle-bold'
  };

  const classes: Record<ValidType, string> = {
    valid: 'text-teal-600 font-semibold',
    invalid: 'text-gray-500 font-medium'
  };

  return (
    <li>
      <p className={'flex items-center gap-2 transition-all text-sm ' + classes[type]}>
        <Icon
          icon={icon[type]}
          width="18"
          className="shrink-0"
        />
        <span>{text}</span>
      </p>
    </li>
  );
}
