import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'src/helper/AxiosInstance';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import Editor from '../Editor';
import InputFile from '../InputFile';
import Select from '../Select';

export type ItemType = 'service' | 'product' | 'package_offer';
type IType = {
  key: ItemType;
  label: string;
};

export const itemTypes: Array<IType> = [
  {
    key: 'service',
    label: 'service'
  },
  {
    key: 'product',
    label: 'product'
  },
  {
    key: 'package_offer',
    label: 'packages-offers'
  }
];

export interface ItemsForm {
  name: string | undefined;
  alt_name: string | undefined;
  image: File | undefined;
  category_id: string | undefined;
  item_id: string | undefined;
  item_type: ItemType;
  items: any[];
}

export default function AddFormBody({
  closeModal,
  reFetching,
  item_type
}: {
  closeModal: any;
  reFetching: any;
  item_type: ItemType;
}) {
  const render = true;
  const { t } = useTranslation();
  const globalValues = {
    name: undefined,
    alt_name: undefined,
    image: undefined,
    category_id: undefined,
    item_id: undefined,
    item_type,
    items: []
  } satisfies ItemsForm;
  const [initialValues, setInitialValues] = useState(globalValues);
  const [errors, setErrors] = useState<ItemsForm | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [categories, setCategories] = useState<any[]>([]);

  const submitHandler = useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      const fd = new FormData();

      for (const key in values) {
        const item = values[key as keyof ItemsForm];

        if (item) {
          if (Object.getPrototypeOf(item).constructor.name === 'File') {
            fd.append(key, item, item?.name);
          } else if (key == 'items') {
            const items = []
              .concat(...item.map((e: any) => Array.from({ length: e.qty }).fill(e)))
              .map((e: any) => e.id);
            for (const index in items) {
              fd.append('item_ids[' + index + ']', items[index]);
            }
          } else {
            fd.append(key, item);
          }
        }
      }

      const { data } = await axiosInstance.post('services/add', fd);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = (value?: string) => {
    prepareRequest(
      {
        url: 'categories',
        params: {
          is_active: 1,
          search_key: value
        }
      },
      (data) => {
        setCategories(data.result?.categories || []);
      }
    );
  };

  const costPrice = useMemo(() => {
    let cost = 0;
    let price = 0;
    const { item_type, items } = formik.values;
    if (['service', 'product'].includes(item_type)) return;

    for (const item of items) {
      cost += Number(item.cost || 0) * (item.qty || 0);
      price += Number(item.price || 0) * (item.qty || 0);
    }
    return {
      cost: CurrencyFormatter(cost),
      price: CurrencyFormatter(price)
    };
  }, [formik.values]);

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="form-group col-span-full">
          <label
            htmlFor="image"
            className="form-label"
          >
            {t('image')}
          </label>
          <InputFile
            defaultValue={formik.values.image}
            onValueChange={function (e: any): void {
              formik.setFieldValue('image', e);
            }}
            accept="image/*"
          />
          {errors?.image ? <span className="form-error">{errors?.image}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">{t('name')}</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.name}
            onChange={(e) => handleChange('name', e)}
          />
          {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">{t('alt-name')}</label>
          <input
            type="text"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.alt_name}
            onChange={(e) => handleChange('alt_name', e)}
          />
          {errors?.alt_name ? <span className="form-error">{errors?.alt_name}</span> : null}
        </div>

        <div className="form-group col-span-full">
          <label className="form-label">
            {t('price')} ({t('sar')})
          </label>
          <input
            type="number"
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={formik.values.price}
            onChange={(e) => handleChange('price', e)}
          />
          {errors?.price ? <span className="form-error">{errors?.price}</span> : null}
        </div>

        <div className="form-group col-span-full">
          <label className="form-label">{t('category')}</label>
          <Select
            type={'single'}
            options={categories}
            value={formik.values.category_id}
            onSelect={function (value: any): any {
              return formik.setFieldValue('category_id', value);
            }}
            onSearchChange={(ev) => getCategories(ev.target.value)}
            optionTxt={'name'}
            optionValue={'id'}
          />

          {errors?.category_id ? <span className="form-error">{errors?.category_id}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">{t('description')}</label>
          <Editor
            value={formik.values.desc}
            onChange={function (value: string | undefined): void {
              formik.setFieldValue('desc', value);
            }}
          />
          {errors?.desc ? <span className="form-error">{errors?.desc}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">{t('alt-description')}</label>
          <Editor
            value={formik.values.alt_desc}
            onChange={function (value: string | undefined): void {
              formik.setFieldValue('alt_desc', value);
            }}
          />
          {errors?.alt_desc ? <span className="form-error">{errors?.alt_desc}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">{t('requirements')}</label>
          <Editor
            value={formik.values.requirements}
            onChange={function (value: string | undefined): void {
              formik.setFieldValue('requirements', value);
            }}
          />
          {errors?.requirements ? <span className="form-error">{errors?.requirements}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">{t('alt-requirements')}</label>
          <Editor
            value={formik.values.alt_requirements}
            onChange={function (value: string | undefined): void {
              formik.setFieldValue('alt_requirements', value);
            }}
          />
          {errors?.alt_requirements ? (
            <span className="form-error">{errors?.alt_requirements}</span>
          ) : null}
        </div>
        <div className="form-group">
          <label className="form-label">{t('steps')}</label>
          <Editor
            value={formik.values.steps}
            onChange={function (value: string | undefined): void {
              formik.setFieldValue('steps', value);
            }}
          />
          {errors?.steps ? <span className="form-error">{errors?.steps}</span> : null}
        </div>
        <div className="form-group">
          <label className="form-label">{t('alt-steps')}</label>
          <Editor
            value={formik.values.alt_steps}
            onChange={function (value: string | undefined): void {
              formik.setFieldValue('alt_steps', value);
            }}
          />
          {errors?.alt_steps ? <span className="form-error">{errors?.alt_steps}</span> : null}
        </div>
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('submit')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
}
