import React from 'react';
import {
  CategoryScale,
  ChartData,
  Chart as ChartJS,
  Filler,
  LineElement,
  LinearScale,
  PluginOptionsByType,
  PointElement,
  Tooltip
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  ChartDataLabels
);

interface IProps {
  data: ChartData<'line'>;
  optionsPlugins?: _DeepPartialObject<PluginOptionsByType<'line'>>;
}

export default function SimpleArea({ data, optionsPlugins }: IProps) {
  return (
    <Line
      options={{
        responsive: true,
        font: {
          family: "'Inter', 'Noto Kufi Arabic', Helvetica, Arial, sans-serif"
        },
        plugins: {
          ...optionsPlugins
        }
      }}
      data={data}
      className="max-h-96 !w-full"
    />
  );
}
