export default function LimitedViewItem({ data, count }: { data: any; count: number }) {
  return (
    <div className="flex items-center gap-1.5">
      {data.slice(0, count).map((client: any) => (
        <p
          key={client.id}
          className="btn-with-icon !rounded-full bg-gray-100 !text-xs !text-gray-600"
        >
          {client.name}
        </p>
      ))}
      {data.length - count >= 1 ? (
        <p className="btn-with-icon !rounded-full bg-gray-100">{data.length - 2}</p>
      ) : null}
      {!data.length ? '-' : null}
    </div>
  );
}
