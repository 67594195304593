import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'src/components/shared/Select';
import axiosInstance from 'src/helper/AxiosInstance';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import Editor from '../shared/Editor';
// import AddUsersForm as AddForm from "../shared/users/AddForm";
import Modal from '../shared/Modal';
import AddUsersForm from '../shared/users/AddForm';

export type ItemType = 'service' | 'product' | 'package_offer';
type IType = {
  key: ItemType;
  label: string;
};

export const itemTypes: Array<IType> = [
  {
    key: 'service',
    label: 'service'
  },
  {
    key: 'product',
    label: 'product'
  },
  {
    key: 'package_offer',
    label: 'packages-offers'
  }
];

export interface ItemsForm {
  name: string | undefined;
  alt_name: string | undefined;
  image: File | undefined;
  category_id: string | undefined;
  item_id: string | undefined;
  items: any[];
}

export default function AddForm({ closeModal, reFetching }: { closeModal: any; reFetching: any }) {
  const render = true;
  const { t } = useTranslation();
  const globalValues = {
    name: undefined,
    alt_name: undefined,
    image: undefined,
    category_id: undefined,
    item_id: undefined,
    items: []
  } satisfies ItemsForm;
  const [initialValues, setInitialValues] = useState(globalValues);
  const [errors, setErrors] = useState<ItemsForm | undefined | any>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [clients, setClients] = useState<any[]>([]);
  const [litigants, setLitigants] = useState<any[]>([]);
  const [lawyers, setLawyers] = useState<any[]>([]);
  const [courts, setCourts] = useState<any[]>([]);
  const [addClientVisible, setAddClientVisible] = useState<boolean>(false);
  const [addLitigantVisible, setAddLitigantVisible] = useState<boolean>(false);
  const [addLawyerVisible, setAddLawyerVisible] = useState<boolean>(false);

  const submitHandler = useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);

      //   const fd = new FormData();

      //   for (const key in values) {
      //     const item = values[key as keyof ItemsForm];

      //     if (item) {
      //       if (Object.getPrototypeOf(item).constructor.name === 'File') {
      //         fd.append(key, item, item?.name);
      //       } else if (key == 'items') {
      //         const items = []
      //           .concat(...item.map((e: any) => Array.from({ length: e.qty }).fill(e)))
      //           .map((e: any) => e.id);
      //         for (const index in items) {
      //           fd.append('item_ids[' + index + ']', items[index]);
      //         }
      //       } else {
      //         fd.append(key, item);
      //       }
      //     }
      //   }

      const { data } = await axiosInstance.post('cases/add', values);
      await reFetching();
      helper.resetForm();
      closeModal(false);
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while creating..');
        }
        return;
      }
      setErrors('Something went wrong while creating..');
    } finally {
      setDisabled(false);
    }
  }, []);

  const { formik, handleChange } = useForm({ initialValues, submitHandler });

  useEffect(() => {
    Promise.all([getClients(), getLitigants(), getCourts(), getLawyers()]);
  }, []);

  const getClients = (value?: string) => {
    prepareRequest(
      {
        url: 'users',
        params: {
          is_active: 1,
          search_key: value,
          user_type: 'client',
          page: 1
        }
      },
      (data) => {
        setClients(data.result?.users?.data || []);
      }
    );
  };
  const getLitigants = (value?: string) => {
    prepareRequest(
      {
        url: 'users',
        params: {
          is_active: 1,
          search_key: value,
          user_type: 'client',
          page: 1
        }
      },
      (data) => {
        const result = (data.result?.users?.data || []).filter((e: any) => !clients.includes(e.id));
        setLitigants(result);
      }
    );
  };
  const getLawyers = (value?: string) => {
    prepareRequest(
      {
        url: 'users',
        params: {
          is_active: 1,
          search_key: value,
          user_type: ['lawyer', 'counselor'],
          page: 1
        }
      },
      (data) => {
        const result = data.result?.users?.data || [];
        return result;
        // setLawyers();
      }
    );
  };
  const getCourts = (value?: string) => {
    prepareRequest(
      {
        url: 'courts',
        params: {
          is_active: 1,
          search_key: value,
          page: 1
        }
      },
      (data) => {
        setCourts(data.result?.courts?.data || []);
      }
    );
  };

  return (
    <>
      <form
        className="space-y-4"
        onSubmit={formik.handleSubmit}
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="space-y-4">
            <div className="form-group">
              <label className="form-label">{t('name')}</label>
              <input
                type="text"
                autoComplete="off"
                placeholder="..."
                className="form-input form-outline"
                value={formik.values.name}
                onChange={(e) => handleChange('name', e)}
              />
              {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
            </div>
            <div className="form-group">
              <label className="form-label">{t('info')}</label>

              <Editor
                value={formik.values.info}
                onChange={function (value: string | undefined): void {
                  formik.setFieldValue('info', value);
                }}
              />
              {errors?.info ? <span className="form-error">{errors?.info}</span> : null}
            </div>
          </div>
          <div className="space-y-4">
            <div className="form-group">
              <div className="flex items-center gap-4 justify-between">
                <p className="form-label">Work team</p>
                <button
                  type="button"
                  className="btn-with-icon !text-primary !p-0"
                  onClick={() => setAddLawyerVisible(true)}
                >
                  <span>Add new</span>
                </button>
              </div>
              <Select
                type={'multi'}
                options={lawyers}
                value={formik.values.lawyer_ids}
                onSelect={function (value: any): any {
                  return formik.setFieldValue('lawyer_ids', value);
                }}
                onSearchChange={(ev) => getLawyers(ev.target.value)}
                optionTxt={'name'}
                optionValue={'id'}
              />

              {errors?.lawyer_ids ? <span className="form-error">{errors?.lawyer_ids}</span> : null}
            </div>

            <div className="form-group">
              <div className="flex items-center gap-4 justify-between">
                <p className="form-label">Clients</p>
                <button
                  type="button"
                  className="btn-with-icon !text-primary !p-0"
                  onClick={() => setAddClientVisible(true)}
                >
                  <span>Add new</span>
                </button>
              </div>
              <Select
                type={'multi'}
                options={clients}
                value={formik.values.client_ids}
                onSelect={function (value: any): any {
                  return formik.setFieldValue('client_ids', value);
                }}
                onSearchChange={(ev) => getClients(ev.target.value)}
                optionTxt={'name'}
                optionValue={'id'}
              />

              {errors?.client_ids ? <span className="form-error">{errors?.client_ids}</span> : null}
            </div>
            <div className="form-group">
              <div className="flex items-center gap-4 justify-between">
                <p className="form-label">Litigants</p>
                <button
                  type="button"
                  className="btn-with-icon !text-primary !p-0"
                  onClick={() => setAddLitigantVisible(true)}
                >
                  <span>Add new</span>
                </button>
              </div>
              <Select
                type={'multi'}
                options={litigants}
                value={formik.values.litigant_ids}
                onSelect={function (value: any): any {
                  return formik.setFieldValue('litigant_ids', value);
                }}
                onSearchChange={(ev) => getLitigants(ev.target.value)}
                optionTxt={'name'}
                optionValue={'id'}
              />

              {errors?.litigant_ids ? (
                <span className="form-error">{errors?.litigant_ids}</span>
              ) : null}
            </div>
            <div className="form-group">
              <label className="form-label">Court</label>
              <Select
                type={'single'}
                options={courts}
                value={formik.values.court_id}
                onSelect={function (value: any): any {
                  return formik.setFieldValue('court_id', value);
                }}
                onSearchChange={(ev) => getCourts(ev.target.value)}
                optionTxt={'name'}
                optionValue={'id'}
              />

              {errors?.court_id ? <span className="form-error">{errors?.court_id}</span> : null}
            </div>
          </div>
        </div>

        <div className="inline-flex gap-3 flex-wrap">
          <button
            className="btn-with-icon bg-primary text-white"
            type="submit"
          >
            {disabled ? (
              <Icon
                icon="svg-spinners:3-dots-fade"
                width={20}
              />
            ) : (
              <span>{t('submit')}</span>
            )}
          </button>
          <button
            className="btn-with-icon outline-btn"
            type="reset"
            onClick={() => closeModal(false)}
          >
            <span>{t('cancel')}</span>
          </button>
        </div>
      </form>
      <Modal
        title="New client"
        visible={addClientVisible}
        handleClose={() => setAddClientVisible(false)}
      >
        <AddUsersForm
          closeModal={setAddClientVisible}
          reFetching={getClients}
          user_type="client"
        />
      </Modal>
      <Modal
        title="New team work"
        visible={addLawyerVisible}
        handleClose={() => setAddLawyerVisible(false)}
      >
        <AddUsersForm
          closeModal={setAddLawyerVisible}
          reFetching={getLawyers}
        />
      </Modal>
      <Modal
        title="New client"
        visible={addLitigantVisible}
        handleClose={() => setAddLitigantVisible(false)}
      >
        <AddUsersForm
          closeModal={setAddLitigantVisible}
          reFetching={getLitigants}
          user_type="client"
        />
      </Modal>
    </>
  );
}
