import { omit } from 'lodash';
import { useEffect, useState } from 'react';
import CalendarEvents from 'src/components/shared/CalendarEvents';
import prepareRequest from 'src/helper/prepareRequest';

export default function index() {
  const tabs = [
    'All',
    'New',
    'Archived',
    'Approved',
    'Pending',
    'Running',
    'Completed',
    'Closed',
    'Cancelled'
  ];

  // const [selectedTab, setSelectedTab] = useState<string>('All');
  let render = true;
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responses, setResponses] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({});
  const [filter, setFilter] = useState<any>({});

  useEffect(() => {
    if (!render) return;
    GetItems();
    render = false;
  }, []);

  function GetItems(params?: any) {
    setIsLoading(true);
    const queries = { ...params, ...filter };
    const paginate = omit(queries, ['total_pages', 'current_page', 'count']);
    const from = new Date().toISOString().substring(0, 10);
    prepareRequest(
      {
        url: 'appointments',
        params: {
          from: paginate.from || from,
          ...paginate
        }
      },
      (data) => {
        const appointments = data.result.appointments;
        setResponses(() => appointments);
        // setPagination(() => ({ ...paginate, ...appointments.pagination }));
      }
    ).finally(() => setIsLoading(false));
  }
  return (
    <div className="space-y-4">
      {/* <div className="overflow-x-auto border-b border-b-gray-200 px-6 pt-4">
        <ul className="w-full flex items-center">
          {tabs.map((tab: string, index: number) => (
            <li key={index}>
              <button
                className={[
                  'text-sm pb-3 px-4 transition',
                  selectedTab == tab ? 'text-primary border-b-2 border-b-primary' : 'text-gray-500'
                ].join(' ')}
                type="button"
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </button>
            </li>
          ))}
        </ul>
      </div> */}
      <div className="p-6">
        <CalendarEvents
          events={responses.map((e: any) => ({
            title: e.name,
            date: e.time,
            id: e.id
          }))}
        />
      </div>
    </div>
  );
}
