import React, { useEffect, useState } from 'react';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Title from 'src/components/shared/Title';
import SimpleBar from 'src/components/shared/charts/SimpleBar';
import SimplePie from 'src/components/shared/charts/SimplePie';
import prepareRequest from 'src/helper/prepareRequest';
import { useTranslation } from 'react-i18next';
import ReportsWrapper, { defaultFilterDate } from 'src/components/reports/ReportsWrapper';
import ItemList from 'src/components/shared/ItemList';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import Table from 'src/components/shared/tables/Table';
import SimpleArea from 'src/components/shared/charts/SimpleArea';
import HorizontalDatePicker from 'src/components/shared/HorizontalDatePicker';

function useHooks() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [summary, setSummary] = useState<any>({});
  const [charts, setCharts] = useState<any>({});

  useEffect(() => {
    Promise.all([getSummary(), getCharts()]).finally(() => setIsLoading(false));
  }, []);

  const getSummary = (params?: any) =>
    prepareRequest(
      {
        url: 'reports/summary',
        params: {
          ...defaultFilterDate,
          ...params
        }
      },
      (data) => {
        setSummary(data.result);
      }
    );
  const getCharts = (params?: any) =>
    prepareRequest(
      {
        url: 'reports/charts',
        params: {
          ...defaultFilterDate,
          ...params
        }
      },
      (data) => {
        setCharts(data.result);
      }
    );

  return { isLoading, summary, charts, getSummary, getCharts };
}

export default function index() {
  const { summary, isLoading, charts, getSummary, getCharts } = useHooks();
  const { t } = useTranslation();

  if (isLoading) return <LoadingComponent />;

  return (
    <ReportsWrapper hasFilter={false}>
      <div className="mx-auto">
        <HorizontalDatePicker
          type="daily"
          onChange={(value) => {
            getSummary({
              from: value.from.toISOString().substring(0, 10),
              to: value.to.toISOString().substring(0, 10)
            });
            getCharts({
              from: value.from.toISOString().substring(0, 10),
              to: value.to.toISOString().substring(0, 10)
            });
          }}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 whitespace-normal">
        <PaymentsCard item={charts.cashier} />
        <div className="sm:col-span-2 space-y-4 flex flex-col">
          <CashiersCard data={summary.cashiers || []} />
          <ExpertsCard data={charts.experts || []} />
        </div>
        <BookingsCard item={summary.bookings} />
        <div className="sm:col-span-2 space-y-4 flex flex-col">
          <TopServicesCard data={charts.top_services || []} />
          <LowestServicesCard data={charts.lowest_services || []} />
        </div>
        <ServicesCard item={summary.services} />
        <div className="sm:col-span-2 space-y-4 flex flex-col">
          <TopPackagesOffersCard data={charts.top_services || []} />
          <LowestPackagesOffersCard data={charts.lowest_services || []} />
        </div>
        <PackagesOffers item={summary.packages_offers} />
        <div className="sm:col-span-2">
          <ClientsCard data={charts.top_clients || []} />
        </div>
        <EmployeesCard item={summary.employees} />
        <InventoryCard item={summary.stock} />
      </div>
      <CashiersTable data={summary.cashiers} />
      <div className="hidden grid-cols-1 lg:grid-cols-2 gap-4">
        <Card className="space-y-4">
          <Title
            title={t('incomes')}
            className="font-semibold !text-center"
          />
          <SimplePie
            data={{
              labels: [
                t('services'),
                t('products'),
                t('packages-offers'),
                t('payments'),
                t('vat'),
                t('center-commissions'),
                t('employee-commissions'),
                t('extra-discount'),
                t('total')
              ],
              datasets: [
                {
                  label: t('total'),

                  data: [
                    summary.income_profits?.services?.total || 0,
                    summary.income_profits?.products?.total || 0,
                    summary.income_profits?.packages_offers?.total || 0,
                    summary.income_profits?.total_payments || 0,
                    summary.income_profits?.total_vat || 0,
                    summary.income_profits?.total_center_commissions || 0,
                    summary.income_profits?.total_employee_commissions || 0,
                    summary.income_profits?.total_extra_discount || 0,
                    summary.income_profits?.profit || 0
                  ],
                  backgroundColor: [
                    '#06b6d4',
                    '#f97316',
                    '#ef4444',
                    '#10b981',
                    '#facc15',
                    '#0284c7',
                    '#4338ca',
                    '#b91c1c',
                    '#22c55e'
                  ]
                }
              ]
            }}
            optionsPlugins={{
              datalabels: {
                formatter: function (value, context) {
                  return CurrencyFormatter(value || 0);
                },
                align: 45,
                anchor: 'center',
                clamp: true,
                color: '#000',
                display: 'auto'
              }
            }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('payments')}
            className="font-semibold !text-center"
          />
          <SimplePie
            data={{
              labels: [t('cash'), t('card'), t('bank_transfer')],
              datasets: [
                {
                  label: t('total'),
                  data: [
                    charts.cashier?.cash || 0,
                    (charts.cashier?.mada || 0) + (charts.cashier?.visa || 0),

                    charts.cashier?.bank_transfer || 0
                  ],
                  backgroundColor: ['#22c55e', '#06b6d4', '#0284c7']
                }
              ]
            }}
            optionsPlugins={{
              datalabels: {
                formatter: function (value, context) {
                  return CurrencyFormatter(value || 0);
                },
                align: 45,
                anchor: 'center',
                clamp: true,
                color: '#000',
                display: 'auto'
              }
            }}
          />
        </Card>

        <Card className="space-y-4 col-span-full">
          <Title
            title={t('top-clients')}
            className="font-semibold !text-center"
          />
          <SimpleArea
            data={{
              labels: charts.top_clients?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.top_clients?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab30850',
                  borderColor: '#eab308',
                  fill: true
                },
                {
                  label: t('total'),
                  data: charts.top_clients?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a650',
                  borderColor: '#14b8a6',
                  fill: true
                }
              ]
            }}
            optionsPlugins={{
              datalabels: {
                align: 'top',
                anchor: 'center',
                clamp: true,
                color: '#000',
                display: 'auto'
              }
            }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('experts')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.experts?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.experts?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.experts?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            optionsPlugins={{
              datalabels: {
                align: 'center',
                anchor: 'center',
                rotation: 90,
                offset: 15,
                clamp: true,
                color: '#000',
                display: 'auto'
              }
            }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('call-centers')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.marketers?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.marketers?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.marketers?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            optionsPlugins={{
              datalabels: {
                align: 'center',
                anchor: 'center',
                rotation: 90,
                offset: 15,
                clamp: true,
                color: '#000',
                display: 'auto'
              }
            }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('top-services')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.top_services?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.top_services?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.top_services?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            // options={{
            //   scales: { x: { stacked: true }, y: { stacked: true } },
            //   interaction: {
            //     mode: 'index' as const,
            //     intersect: false
            //   }
            // }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('low-services')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.lowest_services?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.lowest_services?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.lowest_services?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            // options={{
            //   scales: { x: { stacked: true }, y: { stacked: true } },
            //   interaction: {
            //     mode: 'index' as const,
            //     intersect: false
            //   }
            // }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('top-products')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.top_products?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.top_products?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.top_products?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            // options={{
            //   scales: { x: { stacked: true }, y: { stacked: true } },
            //   interaction: {
            //     mode: 'index' as const,
            //     intersect: false
            //   }
            // }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('low-products')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.lowest_products?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.lowest_products?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.lowest_products?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            // options={{
            //   scales: { x: { stacked: true }, y: { stacked: true } },
            //   interaction: {
            //     mode: 'index' as const,
            //     intersect: false
            //   }
            // }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('top-packages-offers')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.top_packages_offers?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.top_packages_offers?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.top_packages_offers?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            // options={{
            //   scales: { x: { stacked: true }, y: { stacked: true } },
            //   interaction: {
            //     mode: 'index' as const,
            //     intersect: false
            //   }
            // }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('low-packages-offers')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.lowest_packages_offers?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.lowest_packages_offers?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.lowest_packages_offers?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            // options={{
            //   scales: { x: { stacked: true }, y: { stacked: true } },
            //   interaction: {
            //     mode: 'index' as const,
            //     intersect: false
            //   }
            // }}
          />
        </Card>
      </div>
    </ReportsWrapper>
  );
}

function BookingsCard({ item }: any) {
  const { t } = useTranslation();
  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('reservations')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('upcoming-reservations')} />
            <Title title={item.upcoming?.count || 0} />
            <Title
              title={CurrencyFormatter(item.upcoming?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('opened-reservations')} />
            <Title title={item.opened?.count || 0} />
            <Title
              title={CurrencyFormatter(item.opened?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('completed-reservations')} />
            <Title title={item.completed?.count || 0} />
            <Title
              title={CurrencyFormatter(item.completed?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('closed-reservations')} />
            <Title title={item.invoiced?.count || 0} />
            <Title
              title={CurrencyFormatter(item.invoiced?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('cancelled-reservations')} />
            <Title title={item.cancelled?.count || 0} />
            <Title
              title={CurrencyFormatter(item.cancelled?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
      </ul>
    </Card>
  );
}

function ServicesCard({ item }: any) {
  const { t } = useTranslation();
  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className="font-bold !text-gray-800"
              title={t('services')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('upcoming-services')} />
            <Title title={item.upcoming?.count || 0} />
            <Title
              title={CurrencyFormatter(item.upcoming?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('opened-services')} />
            <Title title={item.opened?.count || 0} />
            <Title
              title={CurrencyFormatter(item.opened?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('completed-services')} />
            <Title title={item.completed?.count || 0} />
            <Title
              title={CurrencyFormatter(item.completed?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('closed-services')} />
            <Title title={item.invoiced?.count || 0} />
            <Title
              title={CurrencyFormatter(item.invoiced?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('cancelled-services')} />
            <Title title={item.cancelled?.count || 0} />
            <Title
              title={CurrencyFormatter(item.cancelled?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
      </ul>
    </Card>
  );
}

function InventoryCard({ item }: any) {
  const { t } = useTranslation();
  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className="font-bold !text-gray-800"
              title={t('inventory')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('available')} />

            <Title title={item.available?.count || 0} />
            <Title
              title={CurrencyFormatter(item.available?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('low-stock')} />

            <Title title={item.lowest_stock?.count || 0} />
            <Title
              title={CurrencyFormatter(item.lowest_stock?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('out-off-stock')} />

            <Title title={item.out_off_stock?.count || 0} />
            <Title
              title={CurrencyFormatter(item.out_off_stock?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
      </ul>
    </Card>
  );
}

function PackagesOffers({ item }: any) {
  const { t } = useTranslation();
  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className="font-bold !text-gray-800"
              title={t('packages-offers')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('upcoming-packages-offers')} />
            <Title title={item.upcoming?.count || 0} />
            <Title
              title={CurrencyFormatter(item.upcoming?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('opened-packages-offers')} />
            <Title title={item.opened?.count || 0} />
            <Title
              title={CurrencyFormatter(item.opened?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('completed-packages-offers')} />
            <Title title={item.completed?.count || 0} />
            <Title
              title={CurrencyFormatter(item.completed?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('closed-packages-offers')} />
            <Title title={item.invoiced?.count || 0} />
            <Title
              title={CurrencyFormatter(item.invoiced?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title title={t('cancelled-packages-offers')} />
            <Title title={item.cancelled?.count || 0} />
            <Title
              title={CurrencyFormatter(item.cancelled?.total || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
      </ul>
    </Card>
  );
}

function EmployeesCard({ item }: any) {
  const { t } = useTranslation();
  return (
    <Card className="space-y-4">
      <Title
        className="border-b border-b-gray-200 pb-4 font-bold !text-gray-800 -mx-4 px-4"
        title={t('employees')}
      />
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('management')} />
            <Title
              title={item.admins_count || 0}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('cashiers')} />
            <Title
              title={item.cashiers_count || 0}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('experts')} />
            <Title
              title={item.experts_count || 0}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('call-centers')} />
            <Title
              title={item.marketers_count || 0}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
      </ul>
    </Card>
  );
}

function CashiersTable({ data }: any) {
  const { t } = useTranslation();

  return (
    <Table
      RenderHead={() => (
        <tr>
          <th>{t('name')}</th>
          <th>{t('code')}</th>
          <th>{t('reservations')}</th>
          <th>{t('pay-average')}</th>
          <th>{t('card-amount')}</th>
          <th>{t('cash-amount')}</th>
          <th>{t('bank-transfer-amount')}</th>
        </tr>
      )}
      RenderBody={() =>
        data?.map((item: any) => (
          <tr key={item.id}>
            <td>
              {item.name || '-'}
              <br />
              {item.mobile || '-'}
            </td>
            <td>{item.code || '-'}</td>
            <td>
              {CurrencyFormatter(item.bookings?.total || 0)} - ({item.bookings?.count || 0})
            </td>
            <td>{CurrencyFormatter(item.average || 0)}</td>
            <td>{CurrencyFormatter((item.payments?.mada || 0) + (item.payments?.visa || 0))}</td>
            <td>{CurrencyFormatter(item.payments?.cash || 0)}</td>
            <td>{CurrencyFormatter(item.payments?.bank_transfer || 0)}</td>
          </tr>
        ))
      }
    />
  );
}

function PaymentsCard({ item }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('payments')}
            />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('cash')} />
            <Title
              title={CurrencyFormatter(item.cash || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('card')} />
            <Title
              title={CurrencyFormatter((item.visa || 0) + (item.mada || 0))}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
        <ItemList className="py-3">
          <div className="grid grid-cols-2 gap-2">
            <Title title={t('bank_transfer')} />
            <Title
              title={CurrencyFormatter(item.bank_transfer || 0)}
              className="text-end text-gray-800 font-semibold"
            />
          </div>
        </ItemList>
      </ul>
    </Card>
  );
}

function ClientsCard({ data }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('top-clients')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        {data?.map((item: any) => (
          <ItemList
            className="py-3"
            key={item.id}
          >
            <div className="grid grid-cols-3 gap-2">
              <Title title={item.name || '-'} />
              <Title title={item.count || 0} />
              <Title
                title={CurrencyFormatter(item.total || 0)}
                className="text-end text-gray-800 font-semibold"
              />
            </div>
          </ItemList>
        ))}
        {!data?.length ? (
          <ItemList className="py-3">
            <Title
              title={t('no-data')}
              className="!text-center"
            />
          </ItemList>
        ) : null}
      </ul>
    </Card>
  );
}

function TopServicesCard({ data }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('top-services')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        {data?.map((item: any) => (
          <ItemList
            className="py-3"
            key={item.id}
          >
            <div className="grid grid-cols-3 gap-2">
              <Title title={item.name || '-'} />
              <Title title={item.count || 0} />
              <Title
                title={CurrencyFormatter(item.total || 0)}
                className="text-end text-gray-800 font-semibold"
              />
            </div>
          </ItemList>
        ))}
        {!data?.length ? (
          <ItemList className="py-3">
            <Title
              title={t('no-data')}
              className="!text-center"
            />
          </ItemList>
        ) : null}
      </ul>
    </Card>
  );
}

function LowestServicesCard({ data }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('low-services')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        {data?.map((item: any) => (
          <ItemList
            className="py-3"
            key={item.id}
          >
            <div className="grid grid-cols-3 gap-2">
              <Title title={item.name || '-'} />
              <Title title={item.count || 0} />
              <Title
                title={CurrencyFormatter(item.total || 0)}
                className="text-end text-gray-800 font-semibold"
              />
            </div>
          </ItemList>
        ))}
        {!data?.length ? (
          <ItemList className="py-3">
            <Title
              title={t('no-data')}
              className="!text-center"
            />
          </ItemList>
        ) : null}
      </ul>
    </Card>
  );
}

function TopPackagesOffersCard({ data }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('top-packages-offers')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        {data?.map((item: any) => (
          <ItemList
            className="py-3"
            key={item.id}
          >
            <div className="grid grid-cols-3 gap-2">
              <Title title={item.name || '-'} />
              <Title title={item.count || 0} />
              <Title
                title={CurrencyFormatter(item.total || 0)}
                className="text-end text-gray-800 font-semibold"
              />
            </div>
          </ItemList>
        ))}
        {!data?.length ? (
          <ItemList className="py-3">
            <Title
              title={t('no-data')}
              className="!text-center"
            />
          </ItemList>
        ) : null}
      </ul>
    </Card>
  );
}

function LowestPackagesOffersCard({ data }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('low-packages-offers')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        {data?.map((item: any) => (
          <ItemList
            className="py-3"
            key={item.id}
          >
            <div className="grid grid-cols-3 gap-2">
              <Title title={item.name || '-'} />
              <Title title={item.count || 0} />
              <Title
                title={CurrencyFormatter(item.total || 0)}
                className="text-end text-gray-800 font-semibold"
              />
            </div>
          </ItemList>
        ))}
        {!data?.length ? (
          <ItemList className="py-3">
            <Title
              title={t('no-data')}
              className="!text-center"
            />
          </ItemList>
        ) : null}
      </ul>
    </Card>
  );
}

function CashiersCard({ data }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('cashiers')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        {data?.map((item: any) => (
          <ItemList
            className="py-3"
            key={item.id}
          >
            <div className="grid grid-cols-3 gap-2">
              <Title title={item.name || '-'} />
              <Title title={item.bookings?.count || 0} />
              <Title
                title={CurrencyFormatter(item.bookings?.total || 0)}
                className="text-end text-gray-800 font-semibold"
              />
            </div>
          </ItemList>
        ))}
        {!data?.length ? (
          <ItemList className="py-3">
            <Title
              title={t('no-data')}
              className="!text-center"
            />
          </ItemList>
        ) : null}
      </ul>
    </Card>
  );
}

function ExpertsCard({ data }: any) {
  const { t } = useTranslation();

  return (
    <Card className="space-y-4">
      <ul className="divide-y divide-y-gray-200">
        <ItemList className="py-3">
          <div className="grid grid-cols-3 gap-2">
            <Title
              className=" font-bold !text-gray-800 "
              title={t('experts')}
            />
            <Title title={t('count')} />
            <Title
              title={t('total')}
              className="text-end"
            />
          </div>
        </ItemList>

        {data?.map((item: any) => (
          <ItemList
            className="py-3"
            key={item.id}
          >
            <div className="grid grid-cols-3 gap-2">
              <Title title={item.name || '-'} />
              <Title title={item.count || 0} />
              <Title
                title={CurrencyFormatter(item.total || 0)}
                className="text-end text-gray-800 font-semibold"
              />
            </div>
          </ItemList>
        ))}
        {!data?.length ? (
          <ItemList className="py-3">
            <Title
              title={t('no-data')}
              className="!text-center"
            />
          </ItemList>
        ) : null}
      </ul>
    </Card>
  );
}
