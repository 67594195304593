import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import LoadingComponent from './components/shared/LoadingComponent';
import { authenticationRoutes } from './helper/routes';
import ScrollToTop from './hooks/ScrollToTop';
import useAuth from './hooks/useAuth';
import './i18n';
import HeaderLayout from './layouts/HeaderLayout';

import { useTranslation } from 'react-i18next';
import usePermissions from './hooks/usePermissions';
import { RootState } from './store';

function AppWrapper() {
  const isLoading = useAuth();
  const { token } = useSelector((state: RootState) => state.auth);
  const { i18n } = useTranslation();

  const { filterRoutes } = usePermissions();

  useEffect(() => {
    document.querySelector('html')?.setAttribute('dir', i18n.language === 'ar' ? 'rtl' : 'ltr');
    document.querySelector('html')?.setAttribute('lang', i18n.language);
  }, [i18n]);

  if (isLoading) return <LoadingComponent />;

  return (
    <Fragment>
      {token ? (
        <HeaderLayout>
          <Routes>
            {filterRoutes.map((route: any, index: string | number) => (
              <Route
                key={index}
                element={route.element}
                path={route.path}
              ></Route>
            ))}
          </Routes>
        </HeaderLayout>
      ) : (
        <Fragment>
          <Routes>
            {authenticationRoutes.map((route: any, index: string | number) => (
              <Route
                key={index}
                {...route}
              ></Route>
            ))}
          </Routes>
        </Fragment>
      )}
    </Fragment>
  );
}

function App() {
  return (
    <Router>
      <AppWrapper />
      <ScrollToTop />
    </Router>
  );
}

export default App;

