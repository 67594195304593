import { Icon } from '@iconify/react';
import { useCallback, useState } from 'react';

export default function InputTag({ onChange, values }: any) {
  const [tags, setTags] = useState<string[]>(values);
  const [value, setValue] = useState<string>();

  const onInputChange = useCallback(
    (ev: any) => {
      const { value: val }: any = ev.target;
      const mapTags = [...tags, val];

      setValue(val);

      if (ev.key == 'Enter') {
        ev.preventDefault();
        setTags(mapTags);
        setValue(undefined);
        ev.target.value = null;
        onChange(mapTags);
        return;
      }
    },
    [value, tags]
  );

  const onTagRemove = useCallback(
    (index: number) => {
      const filter = tags.filter((tag: string, idx: number) => idx !== index);
      setTags(filter);
    },
    [tags]
  );

  return (
    <div>
      <div className="flex flex-wrap gap-2 rounded border border-gray-200 p-3 items-center">
        {tags.sort().map((tag: string, index: number) => (
          <button
            className="btn-with-icon !bg-gray-100 !text-gray-500"
            onClick={() => onTagRemove(index)}
            key={index}
            type="button"
          >
            <Icon
              icon="material-symbols:close"
              width="16"
            />
            <span>{tag}</span>
          </button>
        ))}
        <input
          type="text"
          className="flex-1 shrink-0 text-sm"
          placeholder="Type here.."
          defaultValue={value}
          onKeyDown={onInputChange}
        />
      </div>
    </div>
  );
}
