import { Icon } from '@iconify/react';
import { omit } from 'lodash';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ExportOptions from 'src/components/shared/ExportOptions';
import LimitedViewItem from 'src/components/shared/LimitedViewItem';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import AddDocumentForm from 'src/components/shared/documents/AddForm';
import Table from 'src/components/shared/tables/Table';
import prepareRequest from 'src/helper/prepareRequest';

export default function index() {
  let render = true;
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addVisible, setAddVisible] = useState<boolean>(false);
  const [responses, setResponses] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [itemId, setItemId] = useState<string>();

  useEffect(() => {
    if (!render) return;
    GetItems();
    render = false;
  }, []);

  function GetItems(params?: any) {
    setIsLoading(true);
    const queries = { ...params, ...filter };
    const paginate = omit(queries, ['total_pages', 'current_page', 'count']);
    prepareRequest(
      {
        url: 'documents',
        params: {
          page: 1,
          ...paginate
        }
      },
      (data) => {
        const documents = data.result.documents;
        setResponses(() => documents.data);
        setPagination(() => ({ ...paginate, ...documents.pagination }));
      }
    ).finally(() => setIsLoading(false));
  }

  return (
    <div className="p-6">
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>Name</th>
              <th>Case</th>
              <th>Clients</th>
              <th>Lawyers</th>

              <th>Status</th>
              <th>Created date</th>
              <th></th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses.map((item) => (
                <tr key={item.id}>
                  <td>{item.name || '-'}</td>
                  <td>
                    <Link to={'/cases/' + [item.case?.id, item.case?.tracking_id].join('-')}>
                      <p className="text-blue-600">{item.case?.name || '-'}</p>
                    </Link>
                  </td>

                  <td>
                    <LimitedViewItem
                      data={item.case?.clients || []}
                      count={2}
                    />
                  </td>
                  <td>
                    <LimitedViewItem
                      data={item.case?.lawyers || []}
                      count={2}
                    />
                  </td>
                  <td>{item.status || '-'}</td>

                  <td>
                    <SharedTime date={item.created_at} />
                  </td>
                  <td>
                    <div className="actions">
                      <button
                        type="button"
                        className="edit-btn"
                        onClick={() => {
                          setItemId(item.case?.id);
                          setAddVisible(true);
                        }}
                      >
                        <Icon
                          icon="ic:baseline-plus"
                          width="18"
                        />
                        <span>Add new</span>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <ExportOptions
                excelPathname="documents/export_excel"
                cvsPathname="documents/export_csv"
                pathname="documents/send_excel"
                pdfPathname="documents/export_pdf"
              />
            </>
          );
        }}
        isEmpty={!responses.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) =>
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            })),

          onKeyDown: (e) => {
            if (e.key === 'Enter') GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isLoading || isUpdating}
      />
      <Modal
        visible={addVisible}
        handleClose={() => setAddVisible(false)}
        title="Add new"
      >
        <AddDocumentForm
          closeModal={setAddVisible}
          reFetching={GetItems}
          itemId={itemId}
          itemType="case"
        />
      </Modal>
    </div>
  );
}
