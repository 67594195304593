import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import { FormikHelpers, useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import AuthWrapper from 'src/components/account/AuthWrapper';
import Alert from 'src/components/shared/Alert';
import Card from 'src/components/shared/Card';
import Footer from 'src/components/shared/Footer';
import Password from 'src/components/shared/Password';
import axiosInstance from 'src/helper/AxiosInstance';
import { saveUserInfo, storeToken } from 'src/reducers/auth';

interface AuthInterface {
  email_mobile?: string;
  password?: string;
}

const FormBody: React.FC = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const globalValues = {
    email_mobile: undefined,
    password: undefined
  } satisfies AuthInterface;
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = React.useState<AuthInterface>(globalValues);
  const [errors, setErrors] = React.useState<AuthInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);

  const AuthHandler = React.useCallback(async function (
    values: AuthInterface,
    helper: FormikHelpers<AuthInterface>
  ): Promise<any> {
    try {
      setErrors(undefined);
      setDisabled(true);
      const { data } = await axiosInstance.post('login', values);

      const token = data.result.access_token;
      const user = data.result.profile;
      localStorage.setItem('@token', token);
      helper.resetForm();

      dispatch(storeToken(token));
      dispatch(saveUserInfo(user));
      navigate(data.result.go_settings ? '/settings' : '/', { replace: true });

      // window.location.reload();
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          setErrors('Something went wrong while authenticating..');
        }
        return;
      }
      setErrors('Something went wrong while authenticating..');
    } finally {
      setDisabled(false);
    }
  },
  []);

  const { handleSubmit, setFieldValue, values } = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: AuthHandler
  });

  return (
    <form
      className="space-y-3"
      onSubmit={(e) => {
        e.preventDefault();
        console.log('submission from form');
        handleSubmit();
      }}
    >
      {errors ? (
        <Alert
          title="Authentication error"
          type="error"
          content={errors}
        />
      ) : null}

      <div className="form-group">
        <label
          htmlFor="email"
          className="form-label"
        >
          {t('email')}
        </label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="example@example.com"
          className="form-input form-outline !bg-gray-50"
          autoComplete="off"
          autoCapitalize="false"
          autoCorrect="true"
          onChange={({ target }: { target: HTMLInputElement }) =>
            setFieldValue('email_mobile', target.value)
          }
        />
        {errors?.email_mobile ? <span className="form-error">{errors?.email_mobile}</span> : null}
      </div>
      <div className="form-group">
        <label
          htmlFor="password"
          className="form-label"
        >
          {t('password')}
        </label>
        <Password
          name="password"
          id="password"
          className="form-input form-outline !bg-gray-50"
          autoComplete="off"
          autoCapitalize="false"
          autoCorrect="true"
          onChange={({ target }: { target: HTMLInputElement }) =>
            setFieldValue('password', target.value)
          }
        />
        {errors?.password ? <span className="form-error">{errors?.password}</span> : null}
      </div>
      <div className="!mt-8 space-y-2">
        <button
          className="btn-with-icon bg-primary w-full !p-4"
          type="submit"
          disabled={disabled}
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('sign-in')}</span>
          )}
        </button>
        {/* <Link
          className="btn-with-icon !bg-slate-100 !text-slate-600 w-full !p-4"
          to="/account/register"
        >
          <span>{t("create-an-account")}</span>
        </Link> */}
      </div>
    </form>
  );
};

const Login: React.FC = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <AuthWrapper>
      <div className="col-span-full">
        <div className="flex flex-col h-full px-6 mx-auto">
          <div className="flex items-center justify-center w-full h-full py-20 m-auto">
            <Card className="max-w-xl !p-8">
              <Link
                to="/"
                className="table mx-auto"
              >
                <img
                  src="/images/dark-logo.png"
                  alt="website logo"
                  className="w-full max-w-[15rem] object-contain"
                />
              </Link>
              <p className="text-sm text-gray-500 font-medium mt-4 mb-6 text-center ">
                {t('login-content')}
              </p>
              <FormBody />
            </Card>
          </div>
          <Footer />
        </div>
      </div>
    </AuthWrapper>
  );
};

export default Login;
