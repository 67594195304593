import { Icon } from '@iconify/react';
import React from 'react';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  size?: string | number;
  icon?: string;
}

export default function Avatar({
  size = 20,
  icon = 'solar:user-bold',
  className,
  ...props
}: IProps) {
  return (
    <div
      className={[
        'w-10 h-10 rounded-full bg-white shadow-2xl shadow-gray-800/10 border border-gray-200 flex items-center justify-center text-gray-500 relative shrink-0',
        className
      ].join(' ')}
      {...props}
    >
      <Icon
        icon={icon}
        width={size}
        height={size}
      />
    </div>
  );
}
