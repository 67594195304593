import { RouteObject } from 'react-router-dom';
import {
  ActivityLogs,
  AddCase,
  AppointmentDetails,
  Appointments,
  Archives,
  Calendar,
  CaseDetails,
  CaseTracks,
  Cases,
  Categories,
  ChartsSummaryReports,
  Clients,
  ClientsReports,
  Consulting,
  Courts,
  Employees,
  EmployeesReports,
  ErrorPage,
  Home,
  Invoices,
  Login,
  Management,
  Messages,
  Notifications,
  Office,
  Profile,
  Register,
  Reports,
  Services,
  Settings,
  SingleMessage,
  Users
} from 'src/screens';

type Route = RouteObject & {
  permission?: string | string[];
  breadcrumb?: any;
};

const globalRoutes: Route[] = [
  {
    path: '*',
    element: <ErrorPage />
  }
];

const authenticationRoutes: Route[] = [
  {
    path: '/account/login',
    element: <Login />
  },
  {
    path: '/account/register',
    element: <Register />
  },
  ...globalRoutes
];

const routes: Route[] = [
  {
    path: '/',
    element: <Home />,
    permission: 'dashboard'
  },

  {
    path: '/notifications',
    element: <Notifications />,
    permission: 'notifications'
  },

  {
    path: '/messages',
    element: <Messages />,
    permission: 'messages'
  },
  {
    path: '/activity-logs',
    element: <ActivityLogs />,
    permission: 'logs'
  },
  {
    path: '/messages/:id',
    element: <SingleMessage />,
    permission: 'messages'
  },
  {
    path: '/settings',
    element: <Settings />,
    permission: 'settings'
  },

  {
    path: '/settings/experts',
    element: <Employees />,
    permission: 'employees',
    breadcrumb: 'settings'
  },
  {
    path: '/settings/clients',
    element: <Clients />,
    permission: 'clients',
    breadcrumb: 'settings'
  },

  {
    path: '/settings/management',
    element: <Management />,
    permission: 'admins',
    breadcrumb: 'settings'
  },

  {
    path: '/account/profile',
    element: <Profile />,
    permission: 'profile'
  },
  {
    path: '/settings/categories',
    element: <Categories />,
    permission: 'categories',
    breadcrumb: 'settings'
  },

  {
    path: '/settings/employees',
    element: <Users />,
    permission: 'users'
  },
  {
    path: '/settings/services',
    element: <Services />,
    permission: 'services',
    breadcrumb: 'settings'
  },

  {
    path: '/reports',
    element: <Reports />,
    permission: 'reports'
  },
  {
    path: '/reports/charts',
    element: <ChartsSummaryReports />,
    permission: 'reports'
  },

  {
    path: '/reports/clients',
    element: <ClientsReports />,
    permission: 'reports'
  },
  {
    path: '/reports/employees',
    element: <EmployeesReports />,
    permission: 'reports'
  },

  {
    path: '/calendar',
    element: <Calendar />,
    permission: 'reports'
  },
  {
    path: '/archives',
    element: <Archives />,
    permission: 'archives'
  },
  {
    path: '/cases',
    element: <Cases />,
    permission: 'cases'
  },
  {
    path: '/cases/add',
    element: <AddCase />,
    permission: 'cases'
  },
  {
    path: '/appointments',
    element: <Appointments />,
    permission: 'appointments'
  },
  {
    path: '/consulting',
    element: <Consulting />,
    permission: 'consulting'
  },
  {
    path: '/invoices',
    element: <Invoices />,
    permission: 'invoices'
  },
  {
    path: '/settings/courts',
    element: <Courts />,
    permission: 'courts'
  },
  {
    path: '/settings/statuses',
    element: <CaseTracks />,
    permission: 'statuses'
  },
  {
    path: '/settings/offices/:id',
    element: <Office />,
    permission: 'offices'
  },
  {
    path: '/appointments/:id',
    element: <AppointmentDetails />,
    permission: 'appointments'
  },
  {
    path: '/cases/:id',
    element: <CaseDetails />,
    permission: 'cases'
  },

  ...globalRoutes
];

const names = [
  'dashboard',
  'centers',
  'center_owners',
  'invoices',
  'offers',
  'reports',
  'messages',
  'notifications',
  'users',
  'admins',
  'categories',
  'roles',
  'sub_categories',
  'items',
  'features',
  'email_templates',
  'email_notifications',
  'regions',
  'cities',
  'districts',
  'settings'
];
export { authenticationRoutes, globalRoutes, names, routes };
