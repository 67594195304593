import { pick } from 'lodash';
import React, { FC } from 'react';
import axiosInstance from 'src/helper/AxiosInstance';

import { useTranslation } from 'react-i18next';
import ExportOptions from 'src/components/shared/ExportOptions';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import ReportsWrapper, { defaultFilterDate } from '../../../components/reports/ReportsWrapper';

const index: FC = (): JSX.Element => {
  let rerender: boolean = true;
  const { t } = useTranslation();
  const [isUpdating, setIsUpdating] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [responses, setResponses] = React.useState<any[]>([]);
  const [pagination, setPagination] = React.useState<any>({
    page: 1
  });
  const [filter, setFilter] = React.useState<any>({});

  React.useEffect(() => {
    if (rerender) {
      setIsLoading(true);
      Promise.all([GetItems()]).finally(() => {
        setIsLoading(false);
      });
      rerender = false;
    }
  }, []);

  const GetItems = React.useCallback(async function (params?: any) {
    try {
      setIsUpdating(true);
      const paginates = params ? { ...pagination, ...params } : pagination;
      const paginate = pick(paginates, ['page', 'search_key', 'from', 'to']);

      const { data } = await axiosInstance.get('reports/clients', {
        params: { ...defaultFilterDate, ...paginate }
      });
      const { data: items, pagination: responsePaginate } = data?.result?.clients;
      // console.log(data);
      setPagination((values: any) => ({ ...values, ...responsePaginate, ...paginates }));
      setResponses(items);
    } catch (error) {
      console.log('items error fetching...', error);
    } finally {
      setIsUpdating(false);
    }
  }, []);

  const MEMO_TABLE = React.useMemo(() => {
    return (
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>{t('name')}</th>
              <th>{t('code')}</th>
              <th>{t('reservations')}</th>
              <th>{t('opened-reservations')}</th>
              <th>{t('services')}</th>
              <th>{t('upcoming-services')}</th>
              <th>{t('due-amount')}</th>
              <th>{t('total')}</th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses?.map((item: any, index: string | number) => {
                return (
                  <tr key={index}>
                    <td>
                      {item.name || '-'}
                      <br />
                      {item.mobile || '-'}
                    </td>
                    <td>{item.code || '-'}</td>
                    <td>{item.bookings_count || 0}</td>
                    <td>{item.upcoming_bookings_count || 0}</td>
                    <td>{item.services_count || 0}</td>
                    <td>{item.upcoming_services_count || 0}</td>
                    <td>{CurrencyFormatter(item.due_total || 0)}</td>
                    <td>{CurrencyFormatter(item.payments_total || 0)}</td>
                  </tr>
                );
              })}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <ExportOptions
                excelPathname="reports/clients/export_excel"
                cvsPathname="reports/clients/export_csv"
                pathname="reports/clients/send_excel"
                pdfPathname="reports/clients/export_pdf"
              />
            </>
          );
        }}
        isEmpty={!responses?.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) =>
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            })),

          onKeyDown: (e) => {
            if (e.key === 'Enter') GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isLoading || isUpdating}
      />
    );
  }, [responses, isUpdating, pagination]);

  return (
    <ReportsWrapper
      onChange={(value) => GetItems({ page: 1, ...value })}
      hasFilter={false}
    >
      <div className="grid">{MEMO_TABLE}</div>
    </ReportsWrapper>
  );
};

export default index;
