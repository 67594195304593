import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import AuthWrapper from 'src/components/account/AuthWrapper';
import Card from 'src/components/shared/Card';
import Footer from 'src/components/shared/Footer';
import Mobile from 'src/components/shared/Mobile';
import Password from 'src/components/shared/Password';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import Swal from 'sweetalert2';

export default function Register() {
  return (
    <AuthWrapper>
      <div className="col-span-full">
        <div className="flex flex-col h-full px-6 mx-auto">
          <div className="w-full grid grid-cols-1 lg:grid-cols-2 xl:container mx-auto flex-1 pt-4 pb-10">
            <div className="space-y-6 py-10 lg:pe-10 order-2 lg:order-1">
              <div className="space-y-2">
                <p className="text-xl font-bold">Lorem ipsum dolor sit amet.</p>
                <p className="text-base text-gray-500">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fugiat aliquam, maiores
                  odio magnam esse ipsum sed beatae nostrum expedita nam id vero eum tempore nobis
                  quo recusandae ipsam cum voluptate.
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-bold">Lorem ipsum dolor sit amet.</p>
                <p className="text-base text-gray-500">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fugiat aliquam, maiores
                  odio magnam esse ipsum sed beatae nostrum expedita nam id vero eum tempore nobis
                  quo recusandae ipsam cum voluptate.
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-bold">Lorem ipsum dolor sit amet.</p>
                <p className="text-base text-gray-500">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fugiat aliquam, maiores
                  odio magnam esse ipsum sed beatae nostrum expedita nam id vero eum tempore nobis
                  quo recusandae ipsam cum voluptate.
                </p>
              </div>
            </div>
            <div className="order-1 lg:order-2">
              <Card className="!p-8 space-y-6">
                <Link
                  to="/"
                  className="table mx-auto"
                >
                  <img
                    src="/logo.svg"
                    alt="website logo"
                    className="w-full max-w-[15rem] object-contain"
                  />
                </Link>
                <FormBody />
              </Card>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </AuthWrapper>
  );
}

function FormBody() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>();
  const initialValues = {
    name: undefined,
    mobile: undefined,
    email: undefined,
    center_name: undefined,
    centers_count: 1,
    password: undefined
    // cr_no: undefined,
    // vat_no: undefined
  };

  const {
    formik: { values, handleChange, handleSubmit }
  } = useForm({ initialValues, submitHandler });

  function submitHandler(...params: any[]) {
    const [body, helpers] = params;
    setDisabled(true);
    setErrors(undefined);
    prepareRequest(
      {
        url: 'register',
        method: 'post',
        data: {
          ...body,
          mobile: body?.mobile?.replace(/\s/gi, '')
        }
      },
      (data, error) => {
        if (error) return setErrors(error);
        helpers?.resetForm();
        Swal.fire({
          icon: 'success',
          title: data.message,
          confirmButtonText: t('sign-in')
        }).then(() => navigate('/account/login', { replace: true }));
      }
    ).finally(() => setDisabled(false));
  }
  return (
    <form
      className="space-y-6"
      onSubmit={handleSubmit}
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="form-group col-span-full">
          <label
            htmlFor="name"
            className="form-label"
          >
            {t('name')}
          </label>
          <input
            type="text"
            id="name"
            className="form-input"
            value={values.name}
            onChange={handleChange}
            name="name"
            placeholder="Mohammed Abduallah"
          />
          <p className="form-error">{errors?.['name']}</p>
        </div>

        <div className="form-group col-span-full">
          <label
            htmlFor="email"
            className="form-label"
          >
            {t('email')}
          </label>
          <input
            type="email"
            id="email"
            className="form-input"
            value={values.email}
            onChange={handleChange}
            name="email"
            placeholder="example@domain.com"
          />
          <p className="form-error">{errors?.['email']}</p>
        </div>
        <div className="form-group col-span-full">
          <label
            htmlFor="mobile"
            className="form-label"
          >
            {t('mobile')}
          </label>
          <Mobile
            id="mobile"
            className="form-input"
            value={values.mobile}
            onChange={handleChange}
            name="mobile"
            placeholder="05 0000 000"
          />
          <p className="form-error">{errors?.['mobile']}</p>
        </div>
        <div className="form-group col-span-full">
          <label
            htmlFor="password"
            className="form-label"
          >
            {t('password')}
          </label>
          <Password
            id="password"
            className="form-input"
            value={values.password}
            onChange={handleChange}
            name="password"
            placeholder="•••••••"
          />
          <p className="form-error">{errors?.['password']}</p>
        </div>
        <div className="form-group col-span-full">
          <label
            htmlFor="center-name"
            className="form-label"
          >
            {t('center-name')}
          </label>
          <input
            type="text"
            id="center-name"
            className="form-input"
            value={values.center_name}
            onChange={handleChange}
            name="center_name"
            placeholder="Mohammed's center"
          />
          <p className="form-error">{errors?.['center_name']}</p>
        </div>
        <div className="form-group col-span-full">
          <label
            htmlFor="center-name"
            className="form-label"
          >
            {t('centers-count')}
          </label>
          <input
            type="number"
            id="center-name"
            className="form-input"
            value={values.centers_count}
            onChange={handleChange}
            name="centers_count"
            placeholder="1"
          />
          <p className="form-error">{errors?.['centers_count']}</p>
        </div>
        {/* <div className="form-group">
          <label
            htmlFor="cr-number"
            className="form-label"
          >
            {t('cr-no')}
          </label>
          <input
            type="text"
            id="cr-number"
            className="form-input"
            value={values.cr_no}
            onChange={handleChange}
            name="cr_no"
            placeholder="000000000000"
          />
          <p className="form-error">{errors?.['cr_no']}</p>
        </div>
        <div className="form-group">
          <label
            htmlFor="vat-number"
            className="form-label"
          >
            {t('vat-no')}
          </label>
          <input
            type="text"
            id="vat-number"
            className="form-input"
            value={values.vat_no}
            onChange={handleChange}
            name="vat_no"
            placeholder="000000000000"
          />
          <p className="form-error">{errors?.['vat_no']}</p>
        </div> */}
      </div>
      <button
        className="btn-with-icon !bg-primary !p-4 !w-full"
        type="submit"
        disabled={disabled}
      >
        {disabled ? (
          <Icon
            icon="svg-spinners:3-dots-fade"
            width={20}
          />
        ) : (
          <span>{t('join-now')}</span>
        )}
      </button>
    </form>
  );
}
