import { Icon } from '@iconify/react';
import { useState } from 'react';
import Card from 'src/components/shared/Card';
import Editor from 'src/components/shared/Editor';

export default function Add() {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  return (
    <>
      <div className="min-h-screen flex flex-col">
        <div className="py-10 space-y-10 flex-1 xl:container mx-auto">
          <div className="text-center space-y-2">
            <p className="text-2xl text-black font-semibold">Add new case</p>
            <p className="text-base text-gray-500">
              Follow next steps to create a new case successful
            </p>
          </div>
          <Steps activeIndex={activeIndex} />
          <div className="space-y-4 ">
            <Card className="!p-6">
              <form className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                  <div className="form-group">
                    <p className="form-label">Name</p>
                    <input
                      type="text"
                      required
                      placeholder=".."
                      className="form-input form-outline"
                    />
                    <p className="form-error"></p>
                  </div>
                  <div className="form-group">
                    <p className="form-label">Alt name</p>
                    <input
                      type="text"
                      required
                      placeholder=".."
                      className="form-input form-outline"
                    />
                    <p className="form-error"></p>
                  </div>
                  <div className="form-group">
                    <p className="form-label">Short description</p>
                    <textarea
                      placeholder="..."
                      className="form-textarea form-outline"
                    ></textarea>
                    <p className="form-error"></p>
                  </div>
                  <div className="form-group">
                    <p className="form-label">Alt short description</p>
                    <textarea
                      placeholder="..."
                      className="form-textarea form-outline"
                    ></textarea>
                    <p className="form-error"></p>
                  </div>
                  <div className="form-group col-span-full">
                    <p className="form-label">Category</p>
                    <select
                      className="form-select form-outline"
                      defaultValue=""
                    >
                      <option value=""> select </option>
                    </select>
                    <p className="form-error"></p>
                  </div>
                  <div className="form-group col-span-full">
                    <p className="form-label">Description</p>
                    <Editor
                      value={undefined}
                      onChange={function (value: string | undefined): void {
                        console.log(value);
                      }}
                    />
                    <p className="form-error"></p>
                  </div>

                  <div className="form-group col-span-full">
                    <p className="form-label">Alt description</p>
                    <Editor
                      value={undefined}
                      onChange={function (value: string | undefined): void {
                        console.log(value);
                      }}
                    />
                    <p className="form-error"></p>
                  </div>
                  <div className="form-group col-span-full">
                    <p className="form-label">Requirements</p>
                    <Editor
                      value={undefined}
                      onChange={function (value: string | undefined): void {
                        console.log(value);
                      }}
                    />
                    <p className="form-error"></p>
                  </div>

                  <div className="form-group col-span-full">
                    <p className="form-label">Alt requirements</p>
                    <Editor
                      value={undefined}
                      onChange={function (value: string | undefined): void {
                        console.log(value);
                      }}
                    />
                    <p className="form-error"></p>
                  </div>
                </div>
                {/* <AttachmentUploader /> */}
              </form>
            </Card>
          </div>
        </div>
        <div className="w-full sticky bottom-0 z-10 bg-gray-100 p-6">
          <div className="xl:container mx-auto">
            <div className="flex items-center justify-end gap-3">
              <button
                className="btn-with-icon !px-4 !bg-gray-200"
                onClick={() => setActiveIndex(activeIndex - 1)}
              >
                Back
              </button>
              <button
                className="btn-with-icon !px-4 !bg-primary"
                onClick={() => setActiveIndex(activeIndex + 1)}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function Steps({ activeIndex = 0 }: { activeIndex: number }) {
  const [steps, setSteps] = useState<any[]>([
    {
      text: 'Users information',
      icon: 'solar:users-group-two-rounded-bold-duotone'
    },
    {
      text: 'Case details',
      icon: 'solar:notebook-bookmark-bold-duotone'
    },
    {
      text: 'Category & Court',
      icon: 'streamline:legal-justice-scale-1-office-work-legal-scale-justice-company-arbitration-balance-court'
    },
    {
      text: 'Documents',
      icon: 'solar:documents-minimalistic-bold-duotone'
    }
  ]);

  const activeIcon = (index: number) =>
    index <= activeIndex ? 'bg-teal-600 text-white' : 'bg-gray-200 text-gray-600';
  const activeText = (index: number) =>
    index <= activeIndex ? 'text-black font-semibold' : 'text-gray-400 font-normal';
  return (
    <div className="grid justify-center">
      <ul className="flex items-start gap-3 overflow-x-auto">
        {steps.map((step: any, index: number) => (
          <li key={index}>
            <div className="flex flex-col gap-2 items-center justify-center px-3">
              <span
                className={
                  'w-10 h-10 rounded-full flex items-center justify-center transition-all ' +
                  activeIcon(index)
                }
              >
                <Icon
                  icon={step.icon}
                  width="24"
                />
              </span>
              <p className={'text-center text-sm transition-all ' + activeText(index)}>
                {step.text}
              </p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
