import React from 'react';
import {
  ArcElement,
  ChartData,
  Chart as ChartJS,
  CoreChartOptions,
  Legend,
  PluginOptionsByType,
  Tooltip
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { _DeepPartialObject } from 'chart.js/dist/types/utils';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

interface IProps {
  data: ChartData<'doughnut'>;
  core?: CoreChartOptions<'doughnut'>;
  optionsPlugins?: _DeepPartialObject<PluginOptionsByType<'doughnut'>>;
}

export default function SimplePie({ data, core, optionsPlugins }: IProps) {
  return (
    <Doughnut
      options={{
        responsive: true,
        plugins: {
          legend: {
            position: 'bottom'
          },
          ...optionsPlugins
        },
        ...core
      }}
      data={data}
      className="max-h-96 !w-full"
    />
  );
}
