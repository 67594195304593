import { Icon } from '@iconify/react';
import { FC, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Card from 'src/components/shared/Card';
import Image from 'src/components/shared/Image';
import usePermissions from 'src/hooks/usePermissions';
import { RootState } from 'src/store';
import {
  ItemInterface,
  MiniDashboardSingleCard
} from '../../components/shared/MiniDashboardSingleCard';

const Settings: FC = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.auth);
  const { hasPermission } = usePermissions();
  const cards: ItemInterface[] = [
    {
      icon: 'mdi:account-tie-outline',
      color: 'text-gray-500',
      value: t('employees'),
      url: '/settings/employees'
    },
    {
      icon: 'mi:users',
      color: 'text-gray-500',
      value: t('clients'),
      url: '/settings/clients'
    },

    {
      icon: 'solar:dollar-outline',
      color: 'text-gray-500',
      value: t('salaries'),
      url: '/settings/salaries'
    },

    // {
    //   icon: 'carbon:data-center',
    //   color: 'text-gray-500',
    //   value: t('centers'),
    //   url: '/settings/centers'
    // },

    {
      icon: 'fe:layer',
      color: 'text-gray-500',
      value: t('categories'),
      url: '/settings/categories'
    },

    {
      icon: 'solar:checklist-bold',
      color: 'text-gray-500',
      value: 'Case tracks',
      url: '/settings/statuses'
    },
    {
      icon: 'octicon:law-24',
      color: 'text-gray-500',
      value: 'Courts',
      url: '/settings/courts'
    },
    {
      icon: 'iconoir:grid-add',
      color: 'text-gray-500',
      value: t('services'),
      url: '/settings/services'
    },

    {
      icon: 'fluent:edit-settings-24-regular',
      color: 'text-gray-500',
      value: 'Office settings',
      url: '/settings/offices/' + user.office?.id
    }
  ];
  const inventoryCards: ItemInterface[] = [
    {
      icon: 'solar:cart-linear',
      color: 'text-gray-500',
      value: t('purchases'),
      url: '/settings/purchases'
    },
    {
      icon: 'clarity:process-on-vm-line',
      color: 'text-gray-500',
      value: t('processing'),
      url: '/settings/processing'
    },
    {
      icon: 'streamline:interface-arrows-data-transfer-diagonal-square-arrow-square-data-diagonal-internet-transfer-network',
      color: 'text-gray-500',
      value: t('transfers'),
      url: '/settings/transfers'
    },
    {
      icon: 'icon-park-outline:return',
      color: 'text-gray-500',
      value: t('refunds'),
      url: '/settings/refunds'
    }
  ];

  return (
    <Fragment>
      <div className="p-6 space-y-4">
        <Link to="/account/profile">
          <Card>
            <div className="flex items-center gap-4">
              <Image
                src={user?.image}
                className="shrink-0 w-10 h-10 rounded-full"
              />
              <div className="flex-1 grid">
                <p className="text-base font-bold text-gray-800 tabular-nums">
                  {user?.name || 'UNKNOWN'}
                </p>
                <a className="text-sm font-medium text-gray-500 line-clamp-1">
                  {user?.email || 'UNKNOWN'}
                </a>
              </div>
              <span className="text-gray-600">
                <Icon
                  icon="material-symbols:chevron-right"
                  className="rtl:hidden"
                  width="20"
                />
                <Icon
                  icon="material-symbols:chevron-left"
                  className="rtl:block hidden"
                  width="20"
                />
              </span>
            </div>
          </Card>
        </Link>
        {/* <p className="text-sm font-medium text-gray-500">{t('center-details')}</p> */}
        {/* <CenterConfiguration /> */}

        <p className="text-sm font-medium text-gray-500">{t('settings')}</p>
        <div className="grid grid-wrapper gap-3">
          {cards.map((item, i) => (
            <MiniDashboardSingleCard
              key={i}
              item={item}
            />
          ))}
        </div>
        {/* <p className="text-sm font-medium text-gray-500">{t('inventory')}</p>
        <div className="grid grid-wrapper gap-3">
          {inventoryCards.map((item, i) => (
            <MiniDashboardSingleCard
              key={i}
              item={item}
            />
          ))}
        </div> */}
      </div>
    </Fragment>
  );
};

function CenterConfiguration() {
  const { user } = useSelector((state: RootState) => state.auth);
  const { t } = useTranslation();
  return (
    <Card>
      <Link
        to={
          ['owner', 'admin'].includes(user.user_type) ? '/settings/centers/' + user.center?.id : '#'
        }
        className="hidden grid-cols-1 sm:grid-cols-2 gap-3"
      >
        <div className="col-span-full">
          <Image
            className="w-12 h-12 rounded-full object-cover"
            src={user.center?.logo}
          />
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('name')}</p>
          <p className="font-semibold text-gray-700">{user.center?.name || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('email')}</p>
          <p className="font-semibold text-gray-700">{user.center?.email || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('mobile')}</p>
          <p className="font-semibold text-gray-700">{user.center?.mobile || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('address')}</p>
          <p className="font-semibold text-gray-700">{user.center?.address || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('vat-no')}</p>
          <p className="font-semibold text-gray-700">{user.center?.vat_no || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('cr-no')}</p>
          <p className="font-semibold text-gray-700">{user.center?.cr_no || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('bank-name')}</p>
          <p className="font-semibold text-gray-700">{user.center?.bank_name || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">IBAN</p>
          <p className="font-semibold text-gray-700">{user.center?.iban || 'N/A'}</p>
        </div>
        <div className="space-y-1">
          <p className="text-sm text-gray-500">{t('accept-partial-payments')}</p>
          <p className="font-semibold text-gray-700">
            {user.center?.accept_partial_payments ? t('yes') : t('no')}
          </p>
        </div>
      </Link>
    </Card>
  );
}

export default Settings;
