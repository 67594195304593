import { omit } from 'lodash';
import { useEffect, useState } from 'react';
import ExportOptions from 'src/components/shared/ExportOptions';
import HorizontalDatePicker from 'src/components/shared/HorizontalDatePicker';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import prepareRequest from 'src/helper/prepareRequest';

export default function index() {
  let render = true;
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responses, setResponses] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({});
  const [filter, setFilter] = useState<any>({});

  useEffect(() => {
    if (!render) return;
    GetItems();
    render = false;
  }, []);

  function GetItems(params?: any) {
    setIsLoading(true);
    const queries = { ...params, ...filter };
    const paginate = omit(queries, ['total_pages', 'current_page', 'count']);
    const from = new Date().toISOString().substring(0, 10);
    prepareRequest(
      {
        url: 'appointments',
        params: {
          from: paginate.from || from,
          ...paginate
        }
      },
      (data) => {
        const appointments = data.result.appointments;
        setResponses(() => appointments);
        // setPagination(() => ({ ...paginate, ...appointments.pagination }));
      }
    ).finally(() => setIsLoading(false));
  }

  return (
    <div className="p-6 space-y-4">
      <HorizontalDatePicker
        onChange={(value) => {
          GetItems({
            from: value.from.toISOString().substring(0, 10),
            to: value.to.toISOString().substring(0, 10)
          });
        }}
      />
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>Name</th>
              {/* <th>Case</th> */}
              <th>Client</th>
              <th>Time</th>
              <th></th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses.map((item) => (
                <tr key={item.id}>
                  <td>{item.name || '-'}</td>
                  {/* <td>
                    <Link
                      to={'/case/' + item.case?.id}
                      className="text-blue-600 font-semibold"
                    >
                      {item.case?.name}
                    </Link>
                  </td> */}
                  <td>
                    <p>{item.client?.name || '-'}</p>
                    <p>{item.client?.mobile || '-'}</p>
                  </td>

                  <td>
                    <SharedTime
                      date={item.time}
                      format="dddd, lll"
                    />
                  </td>

                  <td></td>
                </tr>
              ))}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <ExportOptions
                excelPathname="appointments/export_excel"
                cvsPathname="appointments/export_csv"
                pathname="appointments/send_excel"
                pdfPathname="appointments/export_pdf"
              />
            </>
          );
        }}
        isEmpty={!responses.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) =>
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            })),

          onKeyDown: (e) => {
            if (e.key === 'Enter') GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isLoading || isUpdating}
      />
    </div>
  );
}
