import React, { useEffect, useState } from 'react';
import Card from 'src/components/shared/Card';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Title from 'src/components/shared/Title';
import SimpleBar from 'src/components/shared/charts/SimpleBar';
import SimplePie from 'src/components/shared/charts/SimplePie';
import prepareRequest from 'src/helper/prepareRequest';
import { useTranslation } from 'react-i18next';
import ReportsWrapper, { defaultFilterDate } from 'src/components/reports/ReportsWrapper';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import Table from 'src/components/shared/tables/Table';
import SimpleArea from 'src/components/shared/charts/SimpleArea';
import HorizontalDatePicker from 'src/components/shared/HorizontalDatePicker';

function useHooks() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [charts, setCharts] = useState<any>({});

  useEffect(() => {
    Promise.all([getCharts()]).finally(() => setIsLoading(false));
  }, []);

  const getCharts = (params?: any) =>
    prepareRequest(
      {
        url: 'reports/charts',
        params: {
          ...defaultFilterDate,
          ...params
        }
      },
      (data) => {
        setCharts(data.result);
      }
    );

  return { isLoading, charts, getCharts };
}

export default function index() {
  const { isLoading, charts, getCharts } = useHooks();
  const { t } = useTranslation();

  if (isLoading) return <LoadingComponent />;

  return (
    <ReportsWrapper hasFilter={false}>
      <div className="mx-auto">
        <HorizontalDatePicker
          type="daily"
          onChange={(value) => {
            getCharts({
              from: value.from.toISOString().substring(0, 10),
              to: value.to.toISOString().substring(0, 10)
            });
          }}
        />
      </div>
      {/* <CashiersTable data={charts.cashiers} /> */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
        <Card className="space-y-4">
          <Title
            title={t('incomes')}
            className="font-semibold !text-center"
          />
          <SimplePie
            data={{
              labels: [
                t('services'),
                t('products'),
                t('packages-offers'),
                t('payments'),
                t('vat'),
                t('center-commissions'),
                t('employee-commissions'),
                t('extra-discount'),
                t('total')
              ],
              datasets: [
                {
                  label: t('total'),

                  data: [
                    charts.income_profits?.services?.total || 0,
                    charts.income_profits?.products?.total || 0,
                    charts.income_profits?.packages_offers?.total || 0,
                    charts.income_profits?.total_payments || 0,
                    charts.income_profits?.total_vat || 0,
                    charts.income_profits?.total_center_commissions || 0,
                    charts.income_profits?.total_employee_commissions || 0,
                    charts.income_profits?.total_extra_discount || 0,
                    charts.income_profits?.profit || 0
                  ],
                  backgroundColor: [
                    '#06b6d4',
                    '#f97316',
                    '#ef4444',
                    '#10b981',
                    '#facc15',
                    '#0284c7',
                    '#4338ca',
                    '#b91c1c',
                    '#22c55e'
                  ]
                }
              ]
            }}
            optionsPlugins={{
              datalabels: {
                formatter: function (value, context) {
                  return CurrencyFormatter(value || 0);
                },
                align: 45,
                anchor: 'center',
                clamp: true,
                color: '#000',
                display: 'auto'
              }
            }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('payments')}
            className="font-semibold !text-center"
          />
          <SimplePie
            data={{
              labels: [t('cash'), t('card'), t('bank_transfer')],
              datasets: [
                {
                  label: t('total'),
                  data: [
                    charts.cashier?.cash || 0,
                    (charts.cashier?.mada || 0) + (charts.cashier?.visa || 0),

                    charts.cashier?.bank_transfer || 0
                  ],
                  backgroundColor: ['#22c55e', '#06b6d4', '#0284c7']
                }
              ]
            }}
            optionsPlugins={{
              datalabels: {
                formatter: function (value, context) {
                  return CurrencyFormatter(value || 0);
                },
                align: 45,
                anchor: 'center',
                clamp: true,
                color: '#000',
                display: 'auto'
              }
            }}
          />
        </Card>

        <Card className="space-y-4 col-span-full">
          <Title
            title={t('top-clients')}
            className="font-semibold !text-center"
          />
          <SimpleArea
            data={{
              labels: charts.top_clients?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.top_clients?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab30850',
                  borderColor: '#eab308',
                  fill: true
                },
                {
                  label: t('total'),
                  data: charts.top_clients?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a650',
                  borderColor: '#14b8a6',
                  fill: true
                }
              ]
            }}
            optionsPlugins={{
              datalabels: {
                align: 'top',
                anchor: 'center',
                clamp: true,
                color: '#000',
                display: 'auto'
              }
            }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('experts')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.experts?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.experts?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.experts?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            optionsPlugins={{
              datalabels: {
                align: 'center',
                anchor: 'center',
                rotation: 90,
                offset: 15,
                clamp: true,
                color: '#000',
                display: 'auto'
              }
            }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('call-centers')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.marketers?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.marketers?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.marketers?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            optionsPlugins={{
              datalabels: {
                align: 'center',
                anchor: 'center',
                rotation: 90,
                offset: 15,
                clamp: true,
                color: '#000',
                display: 'auto'
              }
            }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('top-services')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.top_services?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.top_services?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.top_services?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            // options={{
            //   scales: { x: { stacked: true }, y: { stacked: true } },
            //   interaction: {
            //     mode: 'index' as const,
            //     intersect: false
            //   }
            // }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('low-services')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.lowest_services?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.lowest_services?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.lowest_services?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            // options={{
            //   scales: { x: { stacked: true }, y: { stacked: true } },
            //   interaction: {
            //     mode: 'index' as const,
            //     intersect: false
            //   }
            // }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('top-products')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.top_products?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.top_products?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.top_products?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            // options={{
            //   scales: { x: { stacked: true }, y: { stacked: true } },
            //   interaction: {
            //     mode: 'index' as const,
            //     intersect: false
            //   }
            // }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('low-products')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.lowest_products?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.lowest_products?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.lowest_products?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            // options={{
            //   scales: { x: { stacked: true }, y: { stacked: true } },
            //   interaction: {
            //     mode: 'index' as const,
            //     intersect: false
            //   }
            // }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('top-packages-offers')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.top_packages_offers?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.top_packages_offers?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.top_packages_offers?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            // options={{
            //   scales: { x: { stacked: true }, y: { stacked: true } },
            //   interaction: {
            //     mode: 'index' as const,
            //     intersect: false
            //   }
            // }}
          />
        </Card>
        <Card className="space-y-4">
          <Title
            title={t('low-packages-offers')}
            className="font-semibold !text-center"
          />
          <SimpleBar
            data={{
              labels: charts.lowest_packages_offers?.map((item: any) => item.name),
              datasets: [
                {
                  label: t('count'),
                  data: charts.lowest_packages_offers?.map((item: any) => item.count || 0),
                  backgroundColor: '#eab308'
                },
                {
                  label: t('total'),
                  data: charts.lowest_packages_offers?.map((item: any) => item.total || 0),
                  backgroundColor: '#14b8a6'
                }
              ]
            }}
            // options={{
            //   scales: { x: { stacked: true }, y: { stacked: true } },
            //   interaction: {
            //     mode: 'index' as const,
            //     intersect: false
            //   }
            // }}
          />
        </Card>
      </div>
    </ReportsWrapper>
  );
}
function CashiersTable({ data }: any) {
  const { t } = useTranslation();

  return (
    <Table
      RenderHead={() => (
        <tr>
          <th>{t('name')}</th>
          <th>{t('code')}</th>
          <th>{t('reservations')}</th>
          <th>{t('pay-average')}</th>
          <th>{t('card-amount')}</th>
          <th>{t('cash-amount')}</th>
          <th>{t('bank-transfer-amount')}</th>
        </tr>
      )}
      RenderBody={() =>
        data?.map((item: any) => (
          <tr key={item.id}>
            <td>
              {item.name || '-'}
              <br />
              {item.mobile || '-'}
            </td>
            <td>{item.code || '-'}</td>
            <td>
              {CurrencyFormatter(item.bookings?.total || 0)} - ({item.bookings?.count || 0})
            </td>
            <td>{CurrencyFormatter(item.average || 0)}</td>
            <td>{CurrencyFormatter((item.payments?.mada || 0) + (item.payments?.visa || 0))}</td>
            <td>{CurrencyFormatter(item.payments?.cash || 0)}</td>
            <td>{CurrencyFormatter(item.payments?.bank_transfer || 0)}</td>
          </tr>
        ))
      }
    />
  );
}
