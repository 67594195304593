import { Icon } from '@iconify/react';
import { omit } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ButtonWithCallback from 'src/components/shared/ButtonWithCallback';
import ExportOptions from 'src/components/shared/ExportOptions';
import Modal from 'src/components/shared/Modal';
import SharedTime from 'src/components/shared/SharedTime';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';

export default function index() {
  let render = true;
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [responses, setResponses] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({});
  const [filter, setFilter] = useState<any>({});
  const [approvedVisible, setApprovedVisible] = useState<any>(false);
  const [bookingItem, setBookingItem] = useState<any>({});

  useEffect(() => {
    if (!render) return;
    GetItems();
    render = false;
  }, []);

  function GetItems(params?: any) {
    setIsLoading(true);
    const queries = { ...params, ...filter };
    const paginate = omit(queries, ['total_pages', 'current_page', 'count']);
    prepareRequest(
      {
        url: 'bookings',
        params: {
          page: 1,
          ...paginate
        }
      },
      (data) => {
        const bookings = data.result.bookings;
        setResponses(() => bookings.data);
        setPagination(() => ({ ...paginate, ...bookings.pagination }));
      }
    ).finally(() => setIsLoading(false));
  }

  function changeItemStatus(status: string, id: string) {
    setDisabled(true);
    prepareRequest(
      {
        url: 'bookings/update_status',
        method: 'post',
        data: {
          id,
          status
        }
      },
      (data, error) => {
        generateAlert(data.message, 'success');
        GetItems();
      }
    ).finally(() => setDisabled(false));
  }

  return (
    <div className="p-6">
      <Table
        RenderHead={() => {
          return (
            <tr>
              <th>#</th>
              <th>Client</th>
              <th>Service</th>
              <th>Status</th>
              <th>Budget</th>
              <th>Booking time</th>
              <th>Created date</th>
              <th></th>
            </tr>
          );
        }}
        RenderBody={() => {
          return (
            <>
              {responses.map((item) => (
                <tr key={item.id}>
                  <td>
                    <Link
                      to={'/consulting/' + item.id}
                      className="text-blue-600 font-semibold"
                    >
                      {item.id}
                    </Link>
                  </td>
                  <td>
                    <p>{item.client?.name || '-'}</p>
                    <p>{item.client?.mobile || '-'}</p>
                  </td>
                  <td>
                    <p>{item.service?.name || '-'}</p>
                    <p> {item.service?.category?.name || '-'}</p>
                  </td>
                  <td>{item.status || '-'}</td>
                  <td>{item.total ? CurrencyFormatter(item.total || 0) : 'Free'}</td>
                  <td>
                    <SharedTime
                      date={item.booking_time}
                      format="dddd, ll"
                    />
                  </td>
                  <td>
                    <SharedTime date={item.created_at} />
                  </td>
                  <td>
                    <div className="actions">
                      {item.status === 'pending' ? (
                        <>
                          <ButtonWithCallback
                            callback={() => {
                              setApprovedVisible(true);
                              setBookingItem(item);
                            }}
                            className="btn-with-icon !bg-teal-600 !text-white"
                            options={{
                              showCancelButton: true,
                              confirmButtonText: 'Yes',
                              cancelButtonText: 'No',
                              title: 'Are you sure you want to change item status?'
                            }}
                            disabled={disabled}
                          >
                            <span>Approve</span>
                          </ButtonWithCallback>
                          <ButtonWithCallback
                            callback={() => changeItemStatus('cancelled', item.id)}
                            className="btn-with-icon !bg-red-600 !text-white"
                            options={{
                              showCancelButton: true,
                              confirmButtonText: 'Yes',
                              cancelButtonText: 'No',
                              title: 'Are you sure you want to change item status?'
                            }}
                            disabled={disabled}
                          >
                            <span>Cancel</span>
                          </ButtonWithCallback>
                        </>
                      ) : item.status === 'approved' ? (
                        <ButtonWithCallback
                          callback={() => changeItemStatus('turned_to_case', item.id)}
                          className="btn-with-icon !bg-blue-600 !text-white"
                          options={{
                            showCancelButton: true,
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                            title: 'Are you sure you want to change item status?'
                          }}
                          disabled={disabled}
                        >
                          <span>Turn to case</span>
                        </ButtonWithCallback>
                      ) : null}
                    </div>
                  </td>
                </tr>
              ))}
            </>
          );
        }}
        Actions={() => {
          return (
            <>
              <ExportOptions
                excelPathname="bookings/export_excel"
                cvsPathname="bookings/export_csv"
                pathname="bookings/send_excel"
                pdfPathname="bookings/export_pdf"
              />
            </>
          );
        }}
        isEmpty={!responses.length}
        pagination={pagination}
        searchProps={{
          onChange: (e) =>
            setPagination((values: any) => ({
              ...values,
              search_key: (e.target as HTMLInputElement).value
            })),

          onKeyDown: (e) => {
            if (e.key === 'Enter') GetItems({ search_key: (e.target as HTMLInputElement).value });
          }
        }}
        onNextClick={() => GetItems({ page: pagination.page + 1 })}
        onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
        loading={isLoading || isUpdating}
      />
      <Modal
        visible={approvedVisible}
        title={`${bookingItem?.client?.name} - ${bookingItem?.service?.name}`}
        handleClose={() => setApprovedVisible(false)}
      >
        <ApproveForm
          id={bookingItem.id}
          reFetching={GetItems}
          closeModal={setApprovedVisible}
        />
      </Modal>
    </div>
  );
}

function ApproveForm({
  reFetching,
  closeModal,
  id
}: {
  reFetching: any;
  closeModal: any;
  id: string | undefined;
}) {
  const { t } = useTranslation();
  const globalValues = {
    id,
    booking_time: undefined,
    status: 'approved'
  };
  const [initialValues, setInitialValues] = useState(globalValues);
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);

  function submitHandler(values: any, helper: any) {
    setDisabled(true);
    const booking_time = moment(values.booking_time).format('yyyy-MM-DD hh:mm');
    prepareRequest(
      {
        url: 'bookings/update_status',
        method: 'post',
        data: {
          ...values,
          booking_time
        }
      },
      async (data, error) => {
        if (error) return setErrors(error);
        generateAlert('success', data.message);
        helper.resetForm();
        await reFetching();
        closeModal(false);
      }
    ).finally(() => setDisabled(false));
  }

  const { formik } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      <div className="form-group">
        <label className="form-label">{t('booking-time')}</label>
        <input
          type="datetime-local"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.booking_time}
          name="booking_time"
          onChange={formik.handleChange}
        />
        {errors?.booking_time ? <span className="form-error">{errors?.booking_time}</span> : null}
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('save-changes')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
}
