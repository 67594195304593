import { Icon } from '@iconify/react';
import { AxiosError } from 'axios';
import React from 'react';
import { useTranslation } from 'react-i18next';
import axiosInstance from 'src/helper/AxiosInstance';
import generateAlert from 'src/helper/generateAlert';
import useForm from 'src/hooks/useForm';
import Mobile from '../Mobile';
import Password from '../Password';

export default function UpdateForm({
  closeModal,
  reFetching,
  schema,
  user_type
}: {
  closeModal: any;
  reFetching: any;
  schema: any;
  user_type?: UserType;
}): JSX.Element {
  const [initialValues, setInitialValues] = React.useState(schema);
  const [errors, setErrors] = React.useState<FormInterface | undefined | any>();
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [roles, setRoles] = React.useState<any[]>([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    GetRoles();
  }, []);

  const GetRoles = React.useCallback(async function () {
    try {
      const { data }: any = await axiosInstance.get('roles', { params: { is_active: 1 } });
      setRoles(data?.result?.roles);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const submitHandler = React.useCallback(async function (values: any, helper: any) {
    try {
      setErrors(undefined);
      setDisabled(true);
      const { mobile, ...others } = values;
      const { data } = await axiosInstance.post('users/update', {
        mobile: mobile?.replace(/\s/gi, ''),
        ...others
      });
      await reFetching();
      helper.resetForm();
      closeModal(false);
      generateAlert(data.message, 'success');
    } catch (error: AxiosError | any) {
      if (error instanceof AxiosError) {
        const err = error.response?.data;
        if (err) {
          setErrors(err?.message);
        } else {
          generateAlert('Something went wrong while creating..', 'error');
        }
        return;
      }
      generateAlert('Something went wrong while creating..', 'error');
      console.log(error);
    } finally {
      setDisabled(false);
    }
  }, []);

  const {
    formik: { handleChange, values, setFieldValue, resetForm, handleSubmit }
  } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
    >
      <div className="form-group">
        <label className="form-label">{t('name')}</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={values.name}
          name="name"
          onChange={handleChange}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>

      <div className="form-group">
        <label className="form-label">{t('mobile')}</label>
        <Mobile
          className="form-input form-outline"
          value={values.mobile}
          name="mobile"
          onChange={handleChange}
          dir="ltr"
        />
        {errors?.mobile ? <span className="form-error">{errors?.mobile}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">National ID</label>
        <input
          type="text"
          inputMode="numeric"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={values.national_id}
          name="national_id"
          onChange={handleChange}
        />
        {errors?.national_id ? <span className="form-error">{errors?.national_id}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('email')}</label>
        <input
          type="email"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={values.email}
          name="email"
          onChange={handleChange}
        />
        {errors?.email ? <span className="form-error">{errors?.email}</span> : null}
      </div>
      {!['client'].includes(values.user_type) ? (
        <div className="form-group">
          <label className="form-label">{t('password')}</label>
          <Password
            autoComplete="off"
            placeholder="..."
            className="form-input form-outline"
            value={values.password}
            name="password"
            onChange={handleChange}
          />
          {errors?.password ? <span className="form-error">{errors?.password}</span> : null}
        </div>
      ) : null}

      {values.user_type !== 'client' ? (
        <>
          <div className="form-group">
            <label className="form-label">{t('salary')}</label>
            <input
              type="number"
              autoComplete="off"
              placeholder="0.00 SAR"
              className="form-input form-outline"
              value={values.salary}
              name="salary"
              onChange={handleChange}
              step="any"
            />
            {errors?.salary ? <span className="form-error">{errors?.salary}</span> : null}
          </div>
          {['lawyer', 'counselor'].includes(values.user_type) ? (
            <div className="form-group">
              <label className="form-label">{t('commission')}</label>
              <input
                type="number"
                autoComplete="off"
                placeholder="%00.0"
                className="form-input form-outline"
                value={values.commission}
                name="commission"
                onChange={handleChange}
                step="any"
              />
              {errors?.commission ? <span className="form-error">{errors?.commission}</span> : null}
            </div>
          ) : null}
        </>
      ) : null}
      {/* {values.user_type === 'admin' ? (
        <div className="form-group">
          <label className="form-label">{t('role')}</label>
          <Select
            type={'single'}
            options={roles}
            value={values.role_id}
            onSelect={function (value: any): void {
              setFieldValue('role_id', value);
            }}
            optionTxt={'name'}
            optionValue={'id'}
          />
        </div>
      ) : null} */}

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('save-changes')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
}
