import React, { useState } from 'react';
import InputTag from './InputTag';
import useForm from 'src/hooks/useForm';
import prepareRequest from 'src/helper/prepareRequest';
import generateAlert from 'src/helper/generateAlert';
import { Icon } from '@iconify/react';
import { useTranslation } from 'react-i18next';

export default function SendByEmail({ pathname, handleClose }: any) {
  const [initialValues, setInitialValues] = useState<any>({ emails: [], body: undefined });
  const [disabled, setDisabled] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const { t } = useTranslation();

  const submitHandler = (values: any, helper: any) => {
    setDisabled(true);
    prepareRequest({ url: pathname, method: 'post', data: values }, (data, error) => {
      if (error) return setErrors(error);
      generateAlert(data.message);
      handleClose(false);
    }).finally(() => {
      setDisabled(false);
    });
  };

  const {
    formik: { values, handleChange, handleSubmit, setFieldValue, handleReset }
  } = useForm({ initialValues, submitHandler });
  return (
    <form
      className="space-y-4"
      onSubmit={handleSubmit}
      onReset={handleReset}
    >
      <div className="form-group">
        <p className="form-label">{t('emails')}</p>
        <InputTag
          onChange={(value: any) => setFieldValue('emails', value)}
          values={values.emails}
        />
        {errors.emails ? <p className="form-error">{errors.emails}</p> : null}
      </div>

      <div className="form-group">
        <p className="form-label">{t('notes')}</p>
        <textarea
          className="form-textarea form-outline"
          placeholder="Type your notes here.."
          name="body"
          onChange={handleChange}
        ></textarea>
        {errors.body ? <p className="form-error">{errors.body}</p> : null}
      </div>
      <div className="flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('submit')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => handleClose()}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
}
