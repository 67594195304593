import { EventClickArg } from '@fullcalendar/core';
import { Icon } from '@iconify/react';
import { omit } from 'lodash';
import moment from 'moment';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useParams } from 'react-router-dom';
import ButtonWithCallback from 'src/components/shared/ButtonWithCallback';
import CalendarEvents from 'src/components/shared/CalendarEvents';
import Card from 'src/components/shared/Card';
import Dropdown from 'src/components/shared/Dropdown';
import ItemList from 'src/components/shared/ItemList';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import Modal from 'src/components/shared/Modal';
import SearchBox from 'src/components/shared/SearchBox';
import SharedTime from 'src/components/shared/SharedTime';
import Title from 'src/components/shared/Title';
import AddDocumentForm from 'src/components/shared/documents/AddForm';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import generateAlert from 'src/helper/generateAlert';
import prepareRequest from 'src/helper/prepareRequest';
import useForm from 'src/hooks/useForm';
import AppBar from 'src/layouts/AppBar';

type Tab =
  | 'conversation'
  | 'activities'
  | 'overflow'
  | 'documents'
  | 'events'
  | 'lawyers'
  | 'invoices';
type ITab = {
  icon: string;
  type: Tab;
  title: string;
};

export default function id() {
  let render = true;
  const tabs: ITab[] = [
    {
      icon: 'ant-design:read-outlined',
      type: 'overflow',
      title: 'Overflow'
    },
    // {
    //   icon: 'carbon:chat',
    //   type: 'conversation',
    //   title: 'Conversation'
    // },
    // {
    //   icon: 'solar:list-check-minimalistic-bold',
    //   type: 'activities',
    //   title: 'Activities'
    // },
    {
      icon: 'basil:document-outline',
      type: 'documents',
      title: 'Documents'
    },
    {
      icon: 'solar:calendar-linear',
      type: 'events',
      title: 'Events'
    },
    {
      icon: 'solar:file-text-linear',
      type: 'invoices',
      title: 'Invoices'
    }
    // {
    //   icon: 'heroicons-outline:users',
    //   type: 'lawyers',
    //   title: 'Lawyers'
    // }
  ];
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const tab = searchParams.get('tab') as Tab;
  const [selectedTab, setSelectedTab] = useState<Tab>(tab);
  const [caseDetails, setCaseDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams();
  const caseId = id?.split('-')[0];

  useEffect(() => {
    if (!render) return;
    getCaseDetails();
    render = false;
  }, []);

  function getCaseDetails() {
    setIsLoading(true);
    prepareRequest(
      {
        url: 'cases/' + caseId
      },
      (data) => {
        setCaseDetails(data.result.case || {});
      }
    ).finally(() => setIsLoading(false));
  }

  if (isLoading) return <LoadingComponent />;

  return (
    <>
      <AppBar
        center
        title={caseDetails.name}
      />
      <div className="p-6 xl:container mx-auto">
        <div className="flex flex-col-reverse lg:flex-row items-start gap-8">
          <div className="flex-1 lg:max-w-[15em] lg:sticky top-24 lg:z-10 space-y-4">
            <Title title="Settings" />
            <ul className="space-y-3">
              {tabs.map((tab: ITab, index: number) => (
                <CaseTab
                  key={index}
                  tab={tab}
                  onChange={setSelectedTab}
                />
              ))}
            </ul>
          </div>
          <div className="w-full flex-1 space-y-4">
            {selectedTab === 'overflow' ? (
              <Overflow details={caseDetails} />
            ) : selectedTab === 'events' ? (
              <Events />
            ) : selectedTab === 'documents' ? (
              <Documents />
            ) : selectedTab === 'invoices' ? (
              <Invoices />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
}

function CaseTab({ tab, onChange }: { tab: ITab; onChange: (value: Tab) => any }) {
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const selectedTab = searchParams.get('tab');

  return (
    <li>
      <Link
        to={{
          pathname,
          search: new URLSearchParams({
            tab: tab.type
          }).toString()
        }}
        className={
          'btn-with-icon !text-base !gap-4 !p-0 group !justify-start max-w-fit ' +
          (tab.type == selectedTab || (tab.type == 'overflow' && !selectedTab)
            ? '!text-primary'
            : '!text-gray-600')
        }
        onClick={() => onChange(tab.type)}
      >
        <span
          className={
            'shrink-0 p-2 rounded-full  transition-all ' +
            (tab.type == selectedTab || (tab.type == 'overflow' && !selectedTab)
              ? 'bg-primary !text-black'
              : 'bg-gray-100 group-hover:bg-primary')
          }
        >
          <Icon
            icon={tab.icon}
            width="20"
          />
        </span>
        <span>{tab.title}</span>
      </Link>
    </li>
  );
}

function Overflow({ details }: any) {
  return (
    <>
      <Title title="Case details" />

      <Card className="p-6">
        <ul className="divide-y divide-gray-200">
          <ItemList className="space-y-1 py-4">
            <Title title="Name" />
            <Title
              title={details.name}
              className="!text-base !text-black"
            />
          </ItemList>
          <ItemList className="space-y-1 py-4">
            <Title title="Judge" />
            <Title
              title={details.judge_name || 'N/A'}
              className="!text-base !text-black"
            />
          </ItemList>
          <ItemList className="space-y-1 py-4">
            <Title title="Court" />
            <Title
              title={details.court?.name || 'N/A'}
              className="!text-base !text-black"
            />
          </ItemList>
          <ItemList className="space-y-1 py-4">
            <Title title="Created date" />
            <Title
              title={moment(details.created_at).format('dddd, ll')}
              className="!text-base !text-black"
            />
          </ItemList>
          <ItemList className="space-y-1 py-4">
            <Title title="Details" />
            <div
              dangerouslySetInnerHTML={{
                __html: details.info
              }}
            ></div>
          </ItemList>
        </ul>
      </Card>
      <Title title="Clients" />
      <div className="grid">
        <Table
          RenderHead={() => (
            <tr>
              <th>Name</th>
              <th>Mobile</th>
              <th>National ID</th>
            </tr>
          )}
          RenderBody={() =>
            details.clients?.map((client: any) => (
              <tr key={client.id}>
                <td>{client.name}</td>
                <td>{client.mobile || '-'}</td>
                <td>{client.national_id || '-'}</td>
              </tr>
            ))
          }
          isEmpty={!details.clients?.length}
        />
      </div>
      <Title title="Litigants" />
      <div className="grid">
        <Table
          RenderHead={() => (
            <tr>
              <th>Name</th>
              <th>Mobile</th>
              <th>National ID</th>
            </tr>
          )}
          RenderBody={() =>
            details.litigants?.map((litigant: any) => (
              <tr key={litigant.id}>
                <td>{litigant.name}</td>
                <td>{litigant.mobile || '-'}</td>
                <td>{litigant.national_id || '-'}</td>
              </tr>
            ))
          }
          isEmpty={!details.litigants?.length}
        />
      </div>
    </>
  );
}

// events
function Events() {
  const { t } = useTranslation();
  const [addVisible, setAddVisible] = useState<boolean>(false);
  const [events, setEvents] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [eventItem, setEventItem] = useState<any>({});
  const [editVisible, setEditVisible] = useState<boolean>(false);
  const { id } = useParams();
  const caseId = id?.split('-')[0];

  useEffect(() => {
    getEvents();
  }, []);

  function getEvents() {
    setIsLoading(true);
    prepareRequest(
      {
        url: 'cases/' + id + '/events'
      },
      (data) => {
        const result = data.result.case_events || [];
        setEvents(result);
      }
    ).finally(() => setIsLoading(false));
  }

  function onEventClick(arg: EventClickArg) {
    // console.log(arg);
    const ev: any = events.find((e: any) => e.id == arg.event.id);
    console.log(ev);
    setEventItem(ev);
    setEditVisible(true);
  }

  if (isLoading) return <LoadingComponent />;
  return (
    <>
      <div className="flex gap-3 justify-end items-center">
        <button
          className="btn-with-icon !bg-primary"
          onClick={() => setAddVisible(true)}
        >
          <span>Add new</span>
        </button>
      </div>
      <Card className="p-6 ">
        <CalendarEvents
          events={events.map((e: any) => ({
            title: e.name,
            date: e.event_time,
            id: e.id
          }))}
          eventClick={onEventClick}
        />
      </Card>
      <Modal
        visible={addVisible}
        handleClose={() => setAddVisible(false)}
        title="Add new"
      >
        <AddNewEvent
          closeModal={setAddVisible}
          reFetching={getEvents}
          id={caseId}
        />
      </Modal>
      <Modal
        visible={editVisible}
        handleClose={() => setEditVisible(false)}
        title="Update event"
      >
        <EditEvent
          closeModal={setEditVisible}
          reFetching={getEvents}
          item={eventItem}
          id={caseId}
        />
      </Modal>
    </>
  );
}

function EditEvent({
  reFetching,
  closeModal,
  item,
  id
}: {
  reFetching: any;
  closeModal: any;
  item: any;
  id: any;
}) {
  const { t } = useTranslation();

  const [initialValues, setInitialValues] = useState(item);
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);

  function submitHandler(values: any, helper: any) {
    setDisabled(true);
    prepareRequest(
      {
        url: 'cases/events/update',
        method: 'post',
        data: { ...values, case_id: id }
      },
      async (data, error) => {
        if (error) return setErrors(error);
        generateAlert('success', data.message);
        helper.resetForm();
        await reFetching();
        closeModal(false);
      }
    ).finally(() => setDisabled(false));
  }

  function deleteEvent() {
    setDisabled(true);
    prepareRequest(
      {
        url: 'cases/events/delete/' + item.id,
        method: 'post'
      },
      async (data, error) => {
        if (error) return setErrors(error);
        generateAlert('success', data.message);
        await reFetching();
        closeModal(false);
      }
    ).finally(() => setDisabled(false));
  }

  const { formik } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      <Card className="bg-red-500">
        <div className="flex gap-6 items-center">
          <p className="text-lg font-medium flex-1">Delete event?</p>
          <ButtonWithCallback
            options={{
              confirmButtonText: 'Yes',
              cancelButtonText: 'No',
              showCancelButton: true,
              icon: 'question',
              title: 'Are you sure?'
            }}
            callback={deleteEvent}
            disabled={disabled}
            type="button"
            className="btn-with-icon !bg-red-500 shrink-0 !rounded-full !text-white "
          >
            <span>Delete</span>
          </ButtonWithCallback>
        </div>
      </Card>
      <div className="form-group">
        <label className="form-label">{t('name')}</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.name}
          name="name"
          onChange={formik.handleChange}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('amount')}</label>
        <input
          type="number"
          autoComplete="off"
          placeholder="00"
          className="form-input form-outline"
          value={formik.values.amount}
          name="amount"
          onChange={formik.handleChange}
        />
        {errors?.amount ? <span className="form-error">{errors?.amount}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('date')}</label>
        <input
          type="datetime-local"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.event_time}
          name="event_time"
          onChange={formik.handleChange}
        />
        {errors?.event_time ? <span className="form-error">{errors?.event_time}</span> : null}
      </div>

      <div className="form-group">
        <label className="form-label">{t('info')}</label>
        <textarea
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.info}
          name="info"
          onChange={formik.handleChange}
        ></textarea>
        {errors?.info ? <span className="form-error">{errors?.info}</span> : null}
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('save-changes')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
}

function AddNewEvent({
  reFetching,
  closeModal,
  id
}: {
  reFetching: any;
  closeModal: any;
  id: string | undefined;
}) {
  const { t } = useTranslation();
  const globalValues = {
    case_id: id,
    info: undefined,
    name: undefined,
    event_time: undefined,
    amount: undefined
  };
  const [initialValues, setInitialValues] = useState(globalValues);
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);

  function submitHandler(values: any, helper: any) {
    setDisabled(true);
    prepareRequest(
      {
        url: 'cases/events/add',
        method: 'post',
        data: values
      },
      async (data, error) => {
        if (error) return setErrors(error);
        generateAlert('success', data.message);
        helper.resetForm();
        await reFetching();
        closeModal(false);
      }
    ).finally(() => setDisabled(false));
  }

  const { formik } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      <div className="form-group">
        <label className="form-label">{t('name')}</label>
        <input
          type="text"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.name}
          name="name"
          onChange={formik.handleChange}
        />
        {errors?.name ? <span className="form-error">{errors?.name}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('amount')}</label>
        <input
          type="number"
          autoComplete="off"
          placeholder="00"
          className="form-input form-outline"
          value={formik.values.amount}
          name="amount"
          onChange={formik.handleChange}
        />
        {errors?.amount ? <span className="form-error">{errors?.amount}</span> : null}
      </div>
      <div className="form-group">
        <label className="form-label">{t('date')}</label>
        <input
          type="datetime-local"
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.event_time}
          name="event_time"
          onChange={formik.handleChange}
        />
        {errors?.event_time ? <span className="form-error">{errors?.event_time}</span> : null}
      </div>

      <div className="form-group">
        <label className="form-label">{t('info')}</label>
        <textarea
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.info}
          name="info"
          onChange={formik.handleChange}
        ></textarea>
        {errors?.info ? <span className="form-error">{errors?.info}</span> : null}
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('save-changes')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
}

// documents
function Documents() {
  const { t } = useTranslation();
  const [addVisible, setAddVisible] = useState<boolean>(false);
  const [documents, setDocuments] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams();
  const caseId = id?.split('-')[0];

  useEffect(() => {
    getDocuments();
  }, []);

  function getDocuments(params?: any) {
    setIsLoading(true);
    prepareRequest(
      {
        url: 'documents',
        params: {
          item_id: caseId,
          ...params
        }
      },
      (data) => {
        setDocuments(data.result.documents || []);
      }
    ).finally(() => setIsLoading(false));
  }

  return (
    <>
      <div className="flex gap-3">
        <div className="flex-1">
          <SearchBox
            className="w-full"
            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
              getDocuments({ search_key: ev.target.value })
            }
          />
        </div>
        <button
          className="btn-with-icon !bg-primary"
          onClick={() => setAddVisible(true)}
        >
          <span>Add new</span>
        </button>
      </div>
      <Card className="p-6">
        <ul className="divide-y divide-gray-200">
          {documents.map((document) => (
            <ItemList
              className="py-4 group"
              key={document.id}
            >
              <DocumentCard
                document={document}
                refetching={getDocuments}
              />
            </ItemList>
          ))}
          {!documents.length ? (
            <ItemList className="py-4">
              <p className="text-center text-gray-600 text-sm italic">{t('no-data')}</p>
            </ItemList>
          ) : null}
        </ul>
      </Card>
      <Modal
        visible={addVisible}
        handleClose={() => setAddVisible(false)}
        title="Add new"
      >
        <AddDocumentForm
          closeModal={setAddVisible}
          reFetching={getDocuments}
          itemType="case"
          itemId={caseId}
        />
      </Modal>
    </>
  );
}

type DocumentStatus = 'pending' | 'reviewing' | 'approved' | 'rejected';

function DocumentCard({ document, refetching }: any) {
  const [rejectVisible, setRejectVisible] = useState<boolean>(false);
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);

  const statusClass: Record<DocumentStatus, string> = {
    pending: 'text-gray-600 bg-gray-200',
    reviewing: 'text-white bg-blue-500',
    approved: 'text-white bg-teal-600',
    rejected: 'text-white bg-red-500'
  };

  function updateDocumentStatus() {
    setDisabled(true);
    prepareRequest(
      {
        url: 'documents/update',
        method: 'post',
        data: { status: 'approved', id: document.id }
      },
      async (data, error) => {
        if (error) return setErrors(error);
        generateAlert('success', data.message);
        await refetching();
      }
    ).finally(() => setDisabled(false));
  }

  return (
    <div>
      <p
        className={
          'max-w-fit text-xs rounded-b-lg group-first:rounded-full py-1.5 px-3 font-medium group-first:mt-0 -mt-4 mb-2 capitalize ' +
          statusClass[document.status as DocumentStatus]
        }
      >
        {document.status}
      </p>
      <div className="flex items-start gap-4">
        <div className="flex-1 space-y-1">
          <p className="text-lg font-semibold">{document.name}</p>

          <Title title={document.details} />
          {document.file ? (
            <div className="rounded bg-gray-100 border border-gray-200 p-3 !my-4">
              <div className="flex items-center gap-4">
                <div className="flex-1 space-y-1">
                  {document.notes ? (
                    <Title
                      title={'" ' + document.notes + ' "'}
                      className="font-medium"
                    />
                  ) : null}
                  {document.file ? (
                    <Link
                      to={document.file}
                      className="text-sm underline hover:no-underline"
                      target="_blank"
                    >
                      {document.file}
                    </Link>
                  ) : null}
                </div>
                <Icon
                  className="shrink-0 text-gray-600"
                  icon={
                    document.status == 'approved'
                      ? 'mingcute:check-circle-fill'
                      : document.status === 'reviewing'
                      ? 'fluent:hourglass-half-24-regular'
                      : 'mdi:ban'
                  }
                  width="20"
                />
              </div>
            </div>
          ) : null}
          <Title
            title={moment(document.updated_at).format('dddd, ll')}
            className="!mt-2"
          />
        </div>
        {document.status === 'reviewing' ? (
          <div className="shrink-0">
            <Dropdown
              button={
                <>
                  <Icon icon="tabler:dots" />
                </>
              }
              position="top-right"
              className="!rounded-full !p-1.5"
            >
              <ul className="divide-y divide-gray-200 p-3">
                <ItemList className="py-2">
                  <ButtonWithCallback
                    options={{
                      icon: 'question',
                      cancelButtonText: 'No',
                      confirmButtonText: 'Yes',
                      title: 'Are you sure, you want to do this process?'
                    }}
                    callback={updateDocumentStatus}
                    className="btn-with-icon !p-0 !text-gray-600"
                  >
                    <Icon
                      icon="material-symbols:check-circle"
                      width="20"
                    />
                    <span>Approve</span>
                  </ButtonWithCallback>
                </ItemList>
                <ItemList className="py-2">
                  <button
                    className="btn-with-icon !p-0 !text-gray-600"
                    type="button"
                    onClick={() => setRejectVisible(true)}
                  >
                    <Icon
                      icon="heroicons-solid:ban"
                      width="20"
                    />
                    <span>Reject</span>
                  </button>
                </ItemList>
              </ul>
            </Dropdown>
          </div>
        ) : null}
      </div>

      <Modal
        title={'Reject - ' + document.name}
        handleClose={() => setRejectVisible(false)}
        visible={rejectVisible}
      >
        <RejectForm
          reFetching={refetching}
          id={document.id}
          closeModal={setRejectVisible}
        />
      </Modal>
    </div>
  );
}

function RejectForm({
  reFetching,
  closeModal,
  id
}: {
  reFetching: any;
  closeModal: any;
  id: string;
}) {
  const { t } = useTranslation();
  const globalValues = {
    status: 'rejected',
    id,
    notes: undefined
  };
  const [initialValues, setInitialValues] = useState(globalValues);
  const [errors, setErrors] = useState<any>({});
  const [disabled, setDisabled] = useState<boolean>(false);

  function submitHandler(values: any, helper: any) {
    setDisabled(true);
    prepareRequest(
      {
        url: 'documents/update',
        method: 'post',
        data: values
      },
      async (data, error) => {
        if (error) return setErrors(error);
        generateAlert('success', data.message);
        helper.resetForm();
        await reFetching();
        closeModal(false);
      }
    ).finally(() => setDisabled(false));
  }

  const { formik } = useForm({ initialValues, submitHandler });

  return (
    <form
      className="space-y-4"
      onSubmit={formik.handleSubmit}
    >
      <div className="form-group">
        <label className="form-label">{t('notes')}</label>
        <textarea
          autoComplete="off"
          placeholder="..."
          className="form-input form-outline"
          value={formik.values.notes}
          name="notes"
          onChange={formik.handleChange}
        ></textarea>
        {errors?.notes ? <span className="form-error">{errors?.notes}</span> : null}
      </div>

      <div className="inline-flex gap-3 flex-wrap">
        <button
          className="btn-with-icon bg-primary text-white"
          type="submit"
        >
          {disabled ? (
            <Icon
              icon="svg-spinners:3-dots-fade"
              width={20}
            />
          ) : (
            <span>{t('save-changes')}</span>
          )}
        </button>
        <button
          className="btn-with-icon outline-btn"
          type="reset"
          onClick={() => closeModal(false)}
        >
          <span>{t('cancel')}</span>
        </button>
      </div>
    </form>
  );
}

// invoices
function Invoices() {
  let render = true;
  const { t } = useTranslation();
  const { id } = useParams();
  const caseId = id?.split('-')[0];
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [responses, setResponses] = useState<any[]>([]);
  const [pagination, setPagination] = useState<any>({});
  const [filter, setFilter] = useState<any>({});

  useEffect(() => {
    if (!render) return;
    GetItems();
    render = false;
  }, []);

  function GetItems(params?: any) {
    setIsLoading(true);
    const queries = { ...params, ...filter };
    const paginate = omit(queries, ['total_pages', 'current_page', 'count']);
    prepareRequest(
      {
        url: 'invoices',
        params: {
          page: 1,
          item_id: caseId,
          ...paginate
        }
      },
      (data) => {
        const invoices = data.result.invoices;
        setResponses(() => invoices.data);
        setPagination(() => ({ ...paginate, ...invoices.pagination }));
      }
    ).finally(() => setIsLoading(false));
  }

  return (
    <Table
      RenderHead={() => {
        return (
          <tr>
            <th>#</th>
            <th>Client</th>
            <th>Event</th>
            <th>Status</th>
            <th>Amount</th>
            <th>Paid date</th>
            <th>Created date</th>
            <th></th>
          </tr>
        );
      }}
      RenderBody={() => {
        return (
          <>
            {responses.map((item) => (
              <tr key={item.id}>
                <td>
                  {/* <Link
                      to={'/invoices/' + item.id}
                      className="text-blue-600 font-semibold"
                    >
                    {item.id}
                  </Link> */}
                  {item.id}
                </td>
                <td>
                  <p>{item.client?.name || '-'}</p>
                  <p>{item.client?.mobile || '-'}</p>
                </td>

                <td>{item.case_event?.name || '-'}</td>
                <td>{item.paid_at ? t('paid') : t('unpaid')}</td>
                <td>{CurrencyFormatter(item.amount || 0)}</td>

                <td>
                  <SharedTime date={item.paid_at} />
                </td>
                <td>
                  <SharedTime date={item.created_at} />
                </td>
                <td></td>
              </tr>
            ))}
          </>
        );
      }}
      isEmpty={!responses.length}
      pagination={pagination}
      searchProps={{
        onChange: (e) =>
          setPagination((values: any) => ({
            ...values,
            search_key: (e.target as HTMLInputElement).value
          })),

        onKeyDown: (e) => {
          if (e.key === 'Enter') GetItems({ search_key: (e.target as HTMLInputElement).value });
        }
      }}
      onNextClick={() => GetItems({ page: pagination.page + 1 })}
      onPreviousClick={() => GetItems({ page: pagination.page - 1 })}
      loading={isLoading || isUpdating}
    />
  );
}
