import { omit } from 'lodash';
import { useEffect, useState } from 'react';
import LoadingComponent from 'src/components/shared/LoadingComponent';
import {
  ItemInterface,
  MiniDashboardSingleCard
} from 'src/components/shared/MiniDashboardSingleCard';
import SharedTime from 'src/components/shared/SharedTime';
import Title from 'src/components/shared/Title';
import Table from 'src/components/shared/tables/Table';
import CurrencyFormatter from 'src/helper/CurrencyFormatter';
import prepareRequest from 'src/helper/prepareRequest';

export default function Home() {
  const globalCards: ItemInterface[] = [
    {
      title: 'Today appointments',
      value: '0',
      icon: 'solar:clipboard-list-linear',
      color: '',
      key: 'today_bookings_count'
    },
    {
      title: 'Today consulting',
      value: '0',
      icon: 'mi:users',

      color: '',
      key: 'clients_count'
    },
    {
      title: 'Counselors',
      value: '0',
      icon: 'mdi:account-tie-outline',

      color: '',
      key: 'counselors_count'
    },
    {
      title: 'Employees',
      value: '0',
      icon: 'mdi:account-tie-outline',

      color: '',
      key: 'employees_count'
    },
    {
      title: 'Lawyers',
      value: '0',
      icon: 'mdi:account-tie-outline',

      color: '',
      key: 'lawyers_count'
    },
    {
      title: 'Cases',
      value: '0',
      icon: 'ion:folder-open-outline',
      color: '',
      key: 'cases_count'
    },
    {
      title: 'Invoices',
      value: '0',
      icon: 'solar:file-text-linear',
      color: '',
      key: ''
    }
  ];
  const [cards, setCards] = useState<ItemInterface[]>(globalCards);
  let render = true;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [result, setResult] = useState<any>({});
  const [filter, setFilter] = useState<any>({});

  useEffect(() => {
    if (!render) return;
    GetItems();
    render = false;
  }, []);

  function GetItems(params?: any) {
    setIsLoading(true);
    const queries = { ...params, ...filter };
    const paginate = omit(queries, ['total_pages', 'current_page', 'count']);
    prepareRequest(
      {
        url: 'dashboard',
        params: {
          ...paginate
        }
      },
      (data) => {
        const dashboard = data.result;
        const summary = dashboard.summary || {};
        const collectCards = cards.map((card: ItemInterface) => {
          const key = card.key as string;
          const existed = Object.prototype.hasOwnProperty.call(summary, key);
          return {
            ...card,
            value: existed ? summary[key] : card.value
          };
        });

        setResult(() => dashboard);
        setCards(() => collectCards);
      }
    ).finally(() => setIsLoading(false));
  }

  if (isLoading) return <LoadingComponent />;

  return (
    <div className="p-6">
      <div className="space-y-4">
        <Title title="Summary" />
        <div className="grid grid-wrapper gap-4">
          {cards.map((card, index) => (
            <MiniDashboardSingleCard
              key={index}
              item={card}
            />
          ))}
        </div>
        <Title title="Recent consulting" />
        <Table
          RenderHead={() => {
            return (
              <tr>
                <th>Client</th>
                <th>Service</th>
                <th>Status</th>
                <th>Booking time</th>
              </tr>
            );
          }}
          RenderBody={() => {
            return (
              <>
                {result.recent_bookings?.map((booking: any) => (
                  <tr key={booking.id}>
                    <td>
                      <p>{booking.client?.name || '-'}</p>
                      <p>{booking.client?.mobile || '-'}</p>
                    </td>
                    <td>
                      <p>{booking.service?.name || '-'}</p>
                      {booking.service?.price
                        ? CurrencyFormatter(booking.service?.price || 0)
                        : null}
                    </td>

                    <td>{booking.status || '-'}</td>
                    <td>
                      <SharedTime
                        date={booking.booking_time}
                        format="dddd, ll"
                      />
                    </td>
                  </tr>
                ))}
              </>
            );
          }}
        />
        <Title title="Today appointments" />
        <Table
          RenderHead={() => {
            return (
              <tr>
                <th>Client</th>
                <th>Case</th>
                <th>Category</th>
                <th>Court</th>
                <th>Date</th>
              </tr>
            );
          }}
          RenderBody={() => {
            return (
              <>
                {Array.from({ length: 4 }).map((_e, index) => (
                  <tr key={index}>
                    <td>
                      <p>Client name</p>
                      <p>9665 4509 6681</p>
                    </td>
                    <td>
                      <p>Case name</p>
                      <p> {Date.now()}</p>
                    </td>
                    <td>Family</td>
                    <td>Family Court, Riyadh</td>
                    <td>
                      <SharedTime
                        date={new Date()}
                        format="hh:mm:A"
                      />
                    </td>
                  </tr>
                ))}
              </>
            );
          }}
        />
      </div>
    </div>
  );
}
